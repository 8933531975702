import React, { Component } from "react";
import { Route, Redirect } from "react-router-dom";
import Dashboard from "../Components/Dashboard/Dashboard";
import VisitorPermit from "../Components/VisitorPermit/VisitorPermit";
import UserProfile from "../Components/UserProfile/UserProfile";
import Contact from "../Components/Contact/Contact";
import CurrentPermit from "../Components/CurrentPermit/CurrentPermit";
import Residents from "../Components/Residents/Residents";
import CurrentVisitorPermit from "../Components/CurrentVisitorPermit/CurrentVisitorPermit";
import ViewResidents from "../Components/ViewResidents/ViewResidents";
import AddResidents from "../Components/AddResidents/AddResidents";
import Enforcement from "../Components/Enforcement/Enforcement";
import EnforcementReportPhotoVerify from "../Components/EnforcementReportPhotoVerify/EnforcementReportPhotoVerify";
import EnforcementReportCitationPaidSummary from "../Components/EnforcementReportCitationPaidSummary/EnforcementReportCitationPaidSummary";
import EnforcementReportCitationIssued from "../Components/EnforcementReportCitationIssued/EnforcementReportCitationIssued";
import EnforcementReportCitationAnalysis from "../Components/EnforcementReportCitationAnalysis/EnforcementReportCitationAnalysis";
// import AddQuickPermit from "../Components/AddQuickPermit/QuickPermit";
import AddQuickPermit from "../pages/AddQuickPermit";
import TicketReport from "../Components/TicketReport/TicketReport";
import RevenueReport from "../Components/RevenueReport/RevenueReport";
import DispenserReport from "../Components/TicketDispenserReport/Ticket_dispenser_report";
import VendControlReport from "../Components/VendControlReport/VendControlReport";
import HealthReport from "../Components/HealthReport/HealthReport";
import ResidentPermit from "../Components/ResidentPermit/ResidentPermit";
import EditResident from "../Components/EditResident/EditResident";
import LRPReport from "../Components/LRPReport/LRPReport";
import CurrentOccupancy from "../Components/CurrentOccupancy/CurrentOccupancy";
import ResidentOccupancy from "../Components/ResidentOccupancy/ResidentOccupancy";
import Sidebar from "../Components/Sidebar/Sidebar";
import AdminNavbar from "../Components/Navbars/AdminNavbar";
import sidebarImage from "../../assets/img/sidebar-5.jpg";
import adminDashboardRoutes from "../../adminDashboardRoutes";
import "react-perfect-scrollbar/dist/css/styles.css";
import PlateCheck from "../PlateCheck/PlateCheck";
import SendEmail from "../Components/SendEmail/SendEmailForm";
import AddUnitPermit from "../Components/AddUnitPermit/AddUnitPermit";
import Ticket from "../Components/Ticket/Ticket";
import PassagePass from "../Components/PassagePass/PassagePass";
import TicketList from "../Components/Ticket/TicketList";
import Dispute from "../Components/Ticket/Dispute";
import Footer from "../Components/Footer";
import Privacy from "../Components/PrivacyPolicy/Privacy";
import PrivacyFrench from "../Components/PrivacyPolicy/PrivacyFrench";
import AuditReport from "../Components/AuditReport/AuditReport";
import { connect } from "react-redux";
import { SuperAdminAction } from "../Actions";
import ViewOccupancy from "../Components/LotOccupancy/ViewOccupancy";
import MerchantValidation from "../Components/Validation/Validation";
import RateTracking from "../Components/RateTracking/RatePayment";
import Transactions from "../Components/TransactionReport/TransactionReport";
import Refund from "../Components/Refund/Refund";
import LiveFeed from "../Components/LiveFeed/LiveFeed";
import SmartGate from "../Components/SmartGate/SmartGate";
import LotSharing from "../Components/LotSharing/LotSharing";
import ActivityLog from "../Components/ActivityLog/ActivityLog";
import UnitCreatePermit from "../Components/UnitCreatePermits/UnitCreatePermits";
import AddUserForm from "../Components/AddUser/AddUserForm";
import User from "../Components/AddUser/User";
import UnitPermit from "../Components/UnitPermit/UnitPermit";
import PaidPermit from "../Components/PaidPermit/PaidPermit";
import InvoiceReport from "../Components/InvoiceReports/InvoiceReport";
import InvoiceDetails from "../Components/InvoiceReports/InvoiceDetails";
import CashoutReport from "../Components/Cashouts/cashout";
import MeterBatteryReport from "../Components/MeterBattery/meter_battery_report";
import LengthOfStayReport from "../Components/LengthOfStayReport/LengthOfStayReport";
import MeterStatusReport from "../Components/MeterStatusReport/MeterStatusReport";
import LastTransactionReport from "../Components/LastTransactionReport/LastTransactionReport";
import AccessLogReport from "../Components/AccessLogReport/AccessLogReport";

import "../../../src/assets/css/demo.css";
import PublicRouteInPrivate, { PublicRouteInPrivateList } from "./PublicRouteInPrivate";
import SelfRegisterMainHeader from "../Components/SelfRegister/SelfRegisterMainHeader";
import GenerateValidationCoupons from "../Components/ValidationCoupons/GenerateValidationCoupons";
import CheckCouponValidity from "../Components/ValidationCoupons/CheckCouponValidity";
import CheckParkingDiscountCouponRate from "../Components/ValidationCoupons/CheckParkingDiscountCouponRate";
import AddValidationPassagepass from "../Components/passagepassvalidation/ValidationPassagepass";
import GetScheduler from "../Components/GetScheduler/GetScheduler";
import AddGateScheduler from "../Components/GetScheduler/AddGateScheduler";
import AddRate from "../Components/PassagePassRate/AddPassagepassRate";
import GetRate from "../Components/PassagePassRate/PassagepassRate";
import UserSearch from "../Components/UserSearch/UserSearch";

const AllValidPages = [
  {
    name: "DASHBOARD",
    url: "/dashboard",
  },
  {
    name: "LPR REPORT",
    url: "/lpr-report",
  },
  {
    name: "PARKING LOG",
    url: "/unit-occupancy",
  },
  {
    name: "UNITS AND PERMITS",
    url: "/units",
  },
  {
    name: "OCCUPANCY",
    url: "/view-occupancy",
  },
  {
    name: "UNITS AND PERMITS",
    url: "/add-unit",
  },
  {
    name: "UNITS AND PERMITS",
    url: "/view-units",
  },
  {
    name: "UNITS AND PERMITS",
    url: "/unit-permit",
  },
  {
    name: "UNITS AND PERMITS",
    url: "/edit-unit",
  },
  {
    name: "VIEW ALL PERMITS",
    url: "/current-permits",
  },
  {
    name: "PHOTO VERIFY",
    url: "/photo-verify",
  },
  {
    name: "PLATE AUDIT",
    url: "/current-occupancy",
  },
  {
    name: "PHOTO VERIFY",
    url: "/plate-check",
  },
  {
    name: "PHOTO VERIFY",
    url: "/add-unit-permit",
  },
  {
    name: "PHOTO VERIFY",
    url: "/send-email",
  },
  {
    name: "PHOTO VERIFY",
    url: "/citation",
  },
  {
    name: "PASSAGE PASS",
    url: "/passage-pass",
  },
  {
    name: "PHOTO VERIFY REPORT",
    url: "/audit-report",
  },
  {
    name: "LIVE FEED",
    url: "/live-feed",
  },
  {
    name: "SMART GATE",
    url: "/smart-gate",
  },
  {
    name: "GATE SCHEDULER",
    url: "/gate-scheduler",
  },
  {
    name: "GATE SCHEDULER",
    url: "/add-gate-scheduler",
  },
  {
    name: "MANAGE PARKING RATE",
    url: "/add-parking-rate",
  },
  {
    name: "MANAGE PARKING RATE",
    url: "/edit-parking-rate",
  },
  {
    name: "MANAGE PARKING RATE",
    url: "/parking-rate",
  },
  {
    name: "GATE SCHEDULER",
    url: "/edit-gate-scheduler",
  },
  {
    name: "REPORT",
    url: "/report",
  },
  {
    name: "CITATION REPORT",
    url: "/report-citation",
  },
  {
    name: "REVENUE REPORT",
    url: "/report-revenue",
  },
  {
    name: "TICKET DISPENSER REPORT",
    url: "/report-dispenser",
  },
  {
    name: "VEND CONTROL REPORT",
    url: "/report-vend-control",
  },
  {
    name: "VALIDATION REPORT",
    url: "/report-validation",
  },
  {
    name: "RATE TRACKING REPORT",
    url: "/report-rate-tracking",
  },
  {
    name: "TRANSACTIONS REPORT",
    url: "/report-transactions",
  },
  {
    name: "HEALTH REPORT",
    url: "/report-health",
  },
  {
    name: "PHOTO VERIFY REPORT",
    url: "/report-enforcement-photo-verify",
  },
  {
    name: "CITATION ISSUED REPORT",
    url: "/report-enforcement-citation-issued",
  },
  {
    name: "CITATION PAID REPORT",
    url: "/report-enforcement-citation-paid-summary",
  },
  {
    name: "CITATION ANALYSIS REPORT",
    url: "/report-enforcement-citation-analysis",
  },
  {
    name: "ADD VISITOR",
    url: "/visitor-permit",
  },
  {
    name: "CITATIONS",
    url: "/citation-list",
  },
  {
    name: "CITATIONS",
    url: "/dispute",
  },
  {
    name: "CURRENT VISITORS",
    url: "/current-visitor-permit",
  },
  {
    name: "MY ACCOUNT",
    url: "/user-profile",
  },
  {
    name: "CONTACT SUPPORT",
    url: "/contact",
  },
  // {
  //   name: "USERS",
  //   url: "/dt",
  // },
  {
    name: "USERS",
    url: "/privacy-policy",
  },
  {
    name: "USERS",
    url: "/privacy-policy-french",
  },
  {
    name: "LOT SHARING",
    url: "/lot-sharing",
  },
  {
    name: "ACTIVITY LOGS REPORT",
    url: "/activity-logs-report",
  },
  {
    name: "QUICK PERMITS2",
    url: "/add-quick-permit",
  },
  {
    name: "SELF REGISTER",
    url: "/self-register",
  },
  {
    name: "REFUND REPORT",
    url: "/refund",
  },
  {
    name: "INVOICE REPORT",
    url: "/report-invoice",
  },
  {
    name: "MANAGE PERMIT PACKAGES",
    url: "/managepermit-packages",
  },
  {
    name: "UNIT PERMIT PACKAGES",
    url: "/unitpermit-packages",
  },
  {
    name: "ISSUE PERMIT PACKAGES",
    url: "/issue-permits-packages",
  },
  {
    name: "MANAGE ADMIN",
    url: "/manage-admin",
  },
  {
    name: "USER SEARCH",
    url: "/user-search",
  },
  {
    name: "VALIDATION COUPON",
    url: "/create-validation-coupons",
  },
  {
    name: "PARKING DISCOUNT AUDIT",
    url: "/check-discount-coupon-validity",
  },
  {
    name: "PARKING RATE CHECK",
    url: "/parking-rate-check",
  },
  {
    name: "INVOICE REPORT",
    url: "/invoice-details-report",
  },
  {
    name: "METER BATTERY REPORT",
    url: "/battery-report",
  },
  {
    name: "LENGTH OF STAY REPORT",
    url: "/length_of_stay_report",
  },
  {
    name: "ACCESS LOG REPORT",
    url: "/access_log_report",
  },
  {
    name: "METER STATUS REPORT",
    url: "/meter_status_report",
  },
  {
    name: "LAST TRANSACTION REPORT",
    url: "/last_transaction_report",
  },
  {
    name: "PASSAGEPASS VALIDATION",
    url: "/passagepass-validation",
  },
  {
    name: "CASHOUTS",
    url: "/cashout",
  },
];

class AdminRoutes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      UserProfileDetails: null,
      user_type: "",
      image: sidebarImage,
      color: "black",
      isSidebarHidden: false,
      isShowSidebar: true,
      footerSize: false,
      AllValidPages: AllValidPages,
      routePermission: false,
      mainRoutes: [],
      urlPath: "",
    };
    this.mainPanel = React.createRef(null);
  }

  componentDidMount = async () => {
    this.setState({
      isSidebarHidden: this.props.isSidebarHidden,
      isShowSidebar: this.props.isShowSidebar,
    });
    const selectedRoutes = [];
    await this.props.getPermission();
    if (this.props.api_response) {
      if (this.props.allPermissions) {
        let modifiedPermissionList = [];
        this.props.allPermissions.forEach((element) => {
          modifiedPermissionList.push(element.toLowerCase().replace(/[^a-z]/g, ""));
        });
        modifiedPermissionList.push("DASHBOARD", "CONTACT SUPPORT", "MY ACCOUNT", "USERS", "LOT SHARING");
        const isReportExist = this.props?.allPermissions.find((obj) => obj.toLowerCase().replace(/[^a-z]/g, "") === "report");
        if (!isReportExist) {
          modifiedPermissionList = modifiedPermissionList.filter((item) => !item.includes("report"));
        }
        for (const item of this.state.AllValidPages) {
          const isExits = modifiedPermissionList.find((name) => name.toLowerCase().replace(/[^a-z]/g, "") === item.name.toLowerCase().replace(/[^a-z]/g, ""));
          if (isExits) {
            selectedRoutes.push(item.url);
          }
        }
      }
      this.setState({
        mainRoutes: selectedRoutes,
      });
    }
  };

  handleToogle = () => {
    if (this.state.isSidebarHidden === false) {
      this.setState({ footerSize: true });
    } else {
      this.setState({ footerSize: false });
    }
    this.setState({ isSidebarHidden: !this.state.isSidebarHidden });
  };

  handleSidebarChange = (value) => {
    this.setState({ isSidebarHidden: value });
  };

  isPublicRoute = () => {
    return PublicRouteInPrivateList.find((x) => this.props.location.pathname.indexOf(x) !== -1);
  };

  ShowSideBar = () => {
    if (this.props.isSemiLogin) {
      if (this.props.menu) {
        const itemsToRemove = ["Contact Support", "My Account", "Logout"];
        const modifiedAdminDashboardRoutes = adminDashboardRoutes.filter((route) => {
          return !itemsToRemove.includes(route.name);
        });
        return (
          <Sidebar
            color={this.state.color}
            image={this.state.image}
            isSidebarHidden={this.state.isSidebarHidden}
            routes={modifiedAdminDashboardRoutes}
            handleSidebar={this.handleSidebarChange}
            timezone={this.props.timezone}
            isSemiLogin={this.props.isSemiLogin}
          />
        );
      }
    } else {
      return (
        <Sidebar
          color={this.state.color}
          image={this.state.image}
          isSidebarHidden={this.state.isSidebarHidden}
          routes={adminDashboardRoutes}
          handleSidebar={this.handleSidebarChange}
          timezone={this.props.timezone}
          isSemiLogin={this.props.isSemiLogin}
        />
      );
    }
  };

  getSidebarClass = () => {
    const { isSidebarHidden } = this.state;
    let classname = "main-panel";
    if (isSidebarHidden) {
      classname = "main-panel expanded";
    }
    return classname;
  };

  checkRoutes = (mainRoutes, url) => {
    return mainRoutes.includes(url);
  };

  render() {
    const { isShowSidebar, footerSize, mainRoutes } = this.state;
    const validPaths = [
      "visitor-permit",
      "current-permits",
      "issue-permits-packages",
      "dashboard",
      "units",
      "report-vend-control",
      "audit-report",
      "citation-list",
      "current-occupancy",
      "lpr-report",
      "photo-verify",
      "smart-gate",
      "gate-scheduler",
      "passage-pass",
      "add-quick-permit",
      "check-discount-coupon-validity",
      "check-parking-discount-rate",
      "create-validation-coupons",
      "manage-admin",
      "user-search",
      "unitpermit-packages",
      "managepermit-packages",
      "live-feed",
      "report-enforcement-citation-issued",
    ];
    const currentPath = this.props.location.pathname;

    const isValid = validPaths.some((path) => currentPath.includes(path));
    return (
      <>
        <div className={"wrapper " + (!this.props.menu ? "without-sidebar-full-page" : "")}>
          {this.isPublicRoute() ? (
            <>
              <SelfRegisterMainHeader />
              <PublicRouteInPrivate {...this.props} />
              <div className="full-page">
                <Footer />
              </div>
            </>
          ) : (
            <>
              {this.ShowSideBar()}
              <div className={this.getSidebarClass()} ref={this.mainPanel}>
                {!this.props.isSemiLogin && (
                  <AdminNavbar
                    handleSiderHide={this.handleToogle}
                    domainHeader={this.props.domainHeader}
                    isShowSidebar={isShowSidebar}
                    admin={true}
                    userType={this.props.user_type}
                    adminLogin={this.props.admin_login}
                    permission_id={this.props.permission_id}
                    timezone={this.props.timezone}
                  />
                )}
                <div className="content pt-3 pb-3 h-100">
                  {mainRoutes.length > 0 && (
                    <>
                      {(mainRoutes.indexOf(this.props.location.pathname) !== -1 || isValid) && (
                        <>
                          <Route exact path="/dashboard" render={() => <Dashboard {...this.props} />} />
                          <Route exact path="/dashboard/:menu/:domain/:user/:epoch/:key" render={() => <Dashboard {...this.props} />} />
                          <Route
                            exact
                            path="/units"
                            render={() => {
                              if (this.checkRoutes(mainRoutes, "/units")) {
                                return <Residents {...this.props} />;
                              } else {
                                return <Redirect to={{ pathname: "/dashboard" }} />;
                              }
                            }}
                          />
                          <Route
                            exact
                            path="/units/:menu/:domain/:user/:epoch/:key"
                            render={() => {
                              if (this.checkRoutes(mainRoutes, "/units")) {
                                return <Residents {...this.props} />;
                              } else {
                                return <Redirect to={{ pathname: "/dashboard" }} />;
                              }
                            }}
                          />
                          <Route
                            exact
                            path="/view-units"
                            render={() => {
                              if (this.checkRoutes(mainRoutes, "/view-units")) {
                                return <ViewResidents {...this.props} />;
                              } else {
                                return <Redirect to={{ pathname: "/dashboard" }} />;
                              }
                            }}
                          />
                          <Route
                            exact
                            path="/view-occupancy/:menu/:domain/:user/:epoch/:key"
                            render={() => {
                              if (this.checkRoutes(mainRoutes, "/view-occupancy")) {
                                return <ViewOccupancy {...this.props} />;
                              } else {
                                return <Redirect to={{ pathname: "/dashboard" }} />;
                              }
                            }}
                          />
                          <Route
                            exact
                            path="/view-occupancy"
                            render={() => {
                              if (this.checkRoutes(mainRoutes, "/view-occupancy")) {
                                return <ViewOccupancy {...this.props} />;
                              } else {
                                return <Redirect to={{ pathname: "/dashboard" }} />;
                              }
                            }}
                          />
                          <Route
                            exact
                            path="/visitor-permit"
                            render={() => {
                              if (mainRoutes.includes("/visitor-permit")) {
                                return <VisitorPermit {...this.props} />;
                              } else {
                                return <Redirect to={{ pathname: "/dashboard" }} />;
                              }
                            }}
                          />
                          <Route exact path="/visitor-permit/:menu/:domain/:user/:epoch/:key" render={() => <VisitorPermit {...this.props} />} />
                          <Route
                            exact
                            path="/current-visitor-permit"
                            render={() => {
                              if (mainRoutes.includes("/current-visitor-permit")) {
                                return <CurrentVisitorPermit {...this.props} />;
                              } else {
                                return <Redirect to={{ pathname: "/dashboard" }} />;
                              }
                            }}
                          />
                          <Route exact path="/current-visitor-permit/:menu/:domain/:user/:epoch/:key" render={() => <CurrentVisitorPermit {...this.props} />} />
                          <Route
                            exact
                            path="/create-validation-coupons"
                            render={() => {
                              if (mainRoutes.includes("/create-validation-coupons")) {
                                return <GenerateValidationCoupons {...this.props} />;
                              } else {
                                return <Redirect to={{ pathname: "/dashboard" }} />;
                              }
                            }}
                          />
                          <Route
                            exact
                            path="/create-validation-coupons/:menu/:domain/:user/:epoch/:key"
                            render={() => {
                              if (mainRoutes.includes("/create-validation-coupons")) {
                                return <GenerateValidationCoupons {...this.props} />;
                              } else {
                                return <Redirect to={{ pathname: "/dashboard" }} />;
                              }
                            }}
                          />
                          <Route
                            exact
                            path="/check-discount-coupon-validity"
                            render={() => {
                              if (mainRoutes.includes("/check-discount-coupon-validity")) {
                                return <CheckCouponValidity {...this.props} />;
                              } else {
                                return <Redirect to={{ pathname: "/dashboard" }} />;
                              }
                            }}
                          />
                          <Route
                            exact
                            path="/parking-rate-check"
                            render={() => {
                              if (mainRoutes.includes("/parking-rate-check")) {
                                return <CheckParkingDiscountCouponRate {...this.props} />;
                              } else {
                                return <Redirect to={{ pathname: "/dashboard" }} />;
                              }
                            }}
                          />
                          <Route
                            exact
                            path="/check-discount-coupon-validity/:menu/:domain/:user/:epoch/:key"
                            render={() => {
                              if (mainRoutes.includes("/check-discount-coupon-validity")) {
                                return <CheckCouponValidity {...this.props} />;
                              } else {
                                return <Redirect to={{ pathname: "/dashboard" }} />;
                              }
                            }}
                          />
                          <Route
                            exact
                            path="/passagepass-validation"
                            render={() => {
                              if (mainRoutes.includes("/passagepass-validation")) {
                                return <AddValidationPassagepass {...this.props} />;
                              } else {
                                return <Redirect to={{ pathname: "/dashboard" }} />;
                              }
                            }}
                          />
                          <Route
                            exact
                            path="/passagepass-validation/:menu/:domain/:user/:epoch/:key"
                            render={() => {
                              if (mainRoutes.includes("/passagepass-validation")) {
                                return <AddValidationPassagepass {...this.props} />;
                              } else {
                                return <Redirect to={{ pathname: "/dashboard" }} />;
                              }
                            }}
                          />
                          <Route
                            exact
                            path="/gate-scheduler"
                            render={() => {
                              if (mainRoutes.includes("/gate-scheduler")) {
                                return <GetScheduler {...this.props} />;
                              } else {
                                return <Redirect to={{ pathname: "/dashboard" }} />;
                              }
                            }}
                          />
                          <Route
                            exact
                            path="/gate-scheduler/:menu/:domain/:user/:epoch/:key"
                            render={() => {
                              if (mainRoutes.includes("/gate-scheduler")) {
                                return <GetScheduler {...this.props} />;
                              } else {
                                return <Redirect to={{ pathname: "/dashboard" }} />;
                              }
                            }}
                          />
                          <Route
                            exact
                            path="/add-gate-scheduler"
                            render={() => {
                              if (mainRoutes.includes("/add-gate-scheduler")) {
                                return <AddGateScheduler {...this.props} />;
                              } else {
                                return <Redirect to={{ pathname: "/dashboard" }} />;
                              }
                            }}
                          />
                          <Route
                            exact
                            path="/add-parking-rate"
                            render={() => {
                              if (mainRoutes.includes("/add-parking-rate")) {
                                return <AddRate {...this.props} />;
                              } else {
                                return <Redirect to={{ pathname: "/dashboard" }} />;
                              }
                            }}
                          />
                          <Route
                            exact
                            path="/edit-parking-rate"
                            render={() => {
                              if (mainRoutes.includes("/edit-parking-rate")) {
                                return <AddRate {...this.props} />;
                              } else {
                                return <Redirect to={{ pathname: "/dashboard" }} />;
                              }
                            }}
                          />
                          <Route
                            exact
                            path="/parking-rate"
                            render={() => {
                              if (mainRoutes.includes("/parking-rate")) {
                                return <GetRate {...this.props} />;
                              } else {
                                return <Redirect to={{ pathname: "/dashboard" }} />;
                              }
                            }}
                          />
                          <Route
                            exact
                            path="/add-gate-scheduler/:menu/:domain/:user/:epoch/:key"
                            render={() => {
                              if (mainRoutes.includes("/add-gate-scheduler")) {
                                return <AddGateScheduler {...this.props} />;
                              } else {
                                return <Redirect to={{ pathname: "/dashboard" }} />;
                              }
                            }}
                          />
                          <Route
                            exact
                            path="/edit-gate-scheduler"
                            render={() => {
                              if (mainRoutes.includes("/edit-gate-scheduler")) {
                                return <AddGateScheduler {...this.props} />;
                              } else {
                                return <Redirect to={{ pathname: "/dashboard" }} />;
                              }
                            }}
                          />

                          <Route
                            exact
                            path="/managepermit-packages"
                            render={() => {
                              if (mainRoutes.includes("/managepermit-packages")) {
                                return <PaidPermit {...this.props} />;
                              } else {
                                return <Redirect to={{ pathname: "/dashboard" }} />;
                              }
                            }}
                          />
                          <Route
                            exact
                            path="/managepermit-packages/:menu/:domain/:user/:epoch/:key"
                            render={() => {
                              if (mainRoutes.includes("/managepermit-packages")) {
                                return <PaidPermit {...this.props} />;
                              } else {
                                return <Redirect to={{ pathname: "/dashboard" }} />;
                              }
                            }}
                          />
                          <Route
                            exact
                            path="/unitpermit-packages"
                            render={() => {
                              if (mainRoutes.includes("/unitpermit-packages")) {
                                return <UnitPermit {...this.props} />;
                              } else {
                                return <Redirect to={{ pathname: "/dashboard" }} />;
                              }
                            }}
                          />
                          <Route
                            exact
                            path="/unitpermit-packages/:menu/:domain/:user/:epoch/:key"
                            render={() => {
                              if (mainRoutes.includes("/unitpermit-packages")) {
                                return <UnitPermit {...this.props} />;
                              } else {
                                return <Redirect to={{ pathname: "/dashboard" }} />;
                              }
                            }}
                          />
                          <Route
                            exact
                            path="/issue-permits-packages"
                            render={() => {
                              if (mainRoutes.includes("/issue-permits-packages")) {
                                return <UnitCreatePermit {...this.props} />;
                              } else {
                                return <Redirect to={{ pathname: "/dashboard" }} />;
                              }
                            }}
                          />
                          <Route
                            exact
                            path="/issue-permits-packages/:menu/:domain/:user/:epoch/:key"
                            render={() => {
                              if (mainRoutes.includes("/issue-permits-packages")) {
                                return <UnitCreatePermit {...this.props} />;
                              } else {
                                return <Redirect to={{ pathname: "/dashboard" }} />;
                              }
                            }}
                          />
                          <Route
                            exact
                            path="/manage-admin"
                            render={() => {
                              if (mainRoutes.includes("/manage-admin")) {
                                return <User {...this.props} />;
                              } else {
                                return <Redirect to={{ pathname: "/dashboard" }} />;
                              }
                            }}
                          />
                          <Route
                            exact
                            path="/user-search"
                            render={() => {
                              if (mainRoutes.includes("/user-search")) {
                                return <UserSearch {...this.props} />;
                              } else {
                                return <Redirect to={{ pathname: "/dashboard" }} />;
                              }
                            }}
                          />
                          <Route
                            exact
                            path="/manage-admin/:menu/:domain/:user/:epoch/:key"
                            render={() => {
                              if (mainRoutes.includes("/manage-admin")) {
                                return <AddUserForm {...this.props} />;
                              } else {
                                return <Redirect to={{ pathname: "/dashboard" }} />;
                              }
                            }}
                          />
                          <Route
                            exact
                            path="/current-permits"
                            render={() => {
                              if (mainRoutes.includes("/current-permits")) {
                                return <CurrentPermit {...this.props} />;
                              } else {
                                return <Redirect to={{ pathname: "/dashboard" }} />;
                              }
                            }}
                          />
                          <Route
                            exact
                            path="/current-permits/:menu/:domain/:user/:epoch/:key"
                            render={() => {
                              if (mainRoutes.includes("/current-permits")) {
                                return <CurrentPermit {...this.props} />;
                              } else {
                                return <Redirect to={{ pathname: "/dashboard" }} />;
                              }
                            }}
                          />
                          <Route
                            exact
                            path="/access_log_report"
                            render={() => {
                              if (mainRoutes.includes("/access_log_report")) {
                                return <AccessLogReport {...this.props} />;
                              } else {
                                return <Redirect to={{ pathname: "/dashboard" }} />;
                              }
                            }}
                          />
                          <Route
                            exact
                            path="/report-citation"
                            render={() => {
                              if (mainRoutes.includes("/report-citation")) {
                                return <TicketReport {...this.props} />;
                              } else {
                                return <Redirect to={{ pathname: "/dashboard" }} />;
                              }
                            }}
                          />
                          <Route
                            exact
                            path="/report-revenue"
                            render={() => {
                              if (mainRoutes.includes("/report-revenue")) {
                                return <RevenueReport {...this.props} />;
                              } else {
                                return <Redirect to={{ pathname: "/dashboard" }} />;
                              }
                            }}
                          />
                          <Route
                            exact
                            path="/report-health"
                            render={() => {
                              if (mainRoutes.includes("/report-health")) {
                                return <HealthReport {...this.props} />;
                              } else {
                                return <Redirect to={{ pathname: "/dashboard" }} />;
                              }
                            }}
                          />
                          <Route
                            exact
                            path="/report-enforcement-photo-verify"
                            render={() => {
                              if (mainRoutes.includes("/report-enforcement-photo-verify")) {
                                return <EnforcementReportPhotoVerify {...this.props} />;
                              } else {
                                return <Redirect to={{ pathname: "/dashboard" }} />;
                              }
                            }}
                          />
                          <Route
                            exact
                            path="/report-enforcement-citation-paid-summary"
                            render={() => {
                              if (mainRoutes.includes("/report-enforcement-citation-paid-summary")) {
                                return <EnforcementReportCitationPaidSummary {...this.props} />;
                              } else {
                                return <Redirect to={{ pathname: "/dashboard" }} />;
                              }
                            }}
                          />
                          <Route
                            exact
                            path="/report-enforcement-citation-issued"
                            render={() => {
                              if (mainRoutes.includes("/report-enforcement-citation-issued")) {
                                return <EnforcementReportCitationIssued {...this.props} />;
                              } else {
                                return <Redirect to={{ pathname: "/dashboard" }} />;
                              }
                            }}
                          />
                          <Route
                            exact
                            path="/report-enforcement-citation-issued/:menu/:domain/:user/:epoch/:key"
                            render={() => {
                              if (mainRoutes.includes("/report-enforcement-citation-issued")) {
                                return <EnforcementReportCitationIssued {...this.props} />;
                              } else {
                                return <Redirect to={{ pathname: "/dashboard" }} />;
                              }
                            }}
                          />
                          <Route
                            exact
                            path="/report-enforcement-citation-analysis"
                            render={() => {
                              if (mainRoutes.includes("/report-enforcement-citation-analysis")) {
                                return <EnforcementReportCitationAnalysis {...this.props} />;
                              } else {
                                return <Redirect to={{ pathname: "/dashboard" }} />;
                              }
                            }}
                          />
                          <Route
                            exact
                            path="/photo-verify"
                            render={() => {
                              if (mainRoutes.includes("/photo-verify")) {
                                return <Enforcement {...this.props} />;
                              } else {
                                return <Redirect to={{ pathname: "/dashboard" }} />;
                              }
                            }}
                          />
                          <Route
                            exact
                            path="/photo-verify/:menu/:domain/:user/:epoch/:key"
                            render={() => {
                              if (mainRoutes.includes("/photo-verify")) {
                                return <Enforcement {...this.props} />;
                              } else {
                                return <Redirect to={{ pathname: "/dashboard" }} />;
                              }
                            }}
                          />
                          <Route
                            exact
                            path="/plate-check"
                            render={() => {
                              if (mainRoutes.includes("/plate-check")) {
                                return <PlateCheck {...this.props} />;
                              } else {
                                return <Redirect to={{ pathname: "/dashboard" }} />;
                              }
                            }}
                          />
                          <Route
                            exact
                            path="/send-email"
                            render={() => {
                              if (mainRoutes.includes("/send-email")) {
                                return <SendEmail {...this.props} />;
                              } else {
                                return <Redirect to={{ pathname: "/dashboard" }} />;
                              }
                            }}
                          />
                          <Route
                            exact
                            path="/user-profile"
                            render={() => {
                              if (mainRoutes.includes("/user-profile")) {
                                return <UserProfile {...this.props} />;
                              } else {
                                return <Redirect to={{ pathname: "/dashboard" }} />;
                              }
                            }}
                          />
                          <Route
                            exact
                            path="/contact"
                            render={() => {
                              if (mainRoutes.includes("/contact")) {
                                return <Contact {...this.props} />;
                              } else {
                                return <Redirect to={{ pathname: "/dashboard" }} />;
                              }
                            }}
                          />
                          <Route
                            exact
                            path="/add-unit"
                            render={() => {
                              if (mainRoutes.includes("/add-unit")) {
                                return <AddResidents {...this.props} />;
                              } else {
                                return <Redirect to={{ pathname: "/dashboard" }} />;
                              }
                            }}
                          />
                          <Route
                            exact
                            path="/unit-permit"
                            render={() => {
                              if (mainRoutes.includes("/unit-permit")) {
                                return <ResidentPermit {...this.props} />;
                              } else {
                                return <Redirect to={{ pathname: "/dashboard" }} />;
                              }
                            }}
                          />
                          <Route
                            exact
                            path="/edit-unit"
                            render={() => {
                              if (mainRoutes.includes("/edit-unit")) {
                                return <EditResident {...this.props} />;
                              } else {
                                return <Redirect to={{ pathname: "/dashboard" }} />;
                              }
                            }}
                          />
                          <Route
                            exact
                            path="/lpr-report"
                            render={() => {
                              if (mainRoutes.includes("/lpr-report")) {
                                return <LRPReport {...this.props} />;
                              } else {
                                return <Redirect to={{ pathname: "/dashboard" }} />;
                              }
                            }}
                          />
                          <Route
                            exact
                            path="/lpr-report/:menu/:domain/:user/:epoch/:key"
                            render={() => {
                              if (mainRoutes.includes("/lpr-report")) {
                                return <LRPReport {...this.props} />;
                              } else {
                                return <Redirect to={{ pathname: "/dashboard" }} />;
                              }
                            }}
                          />
                          <Route
                            exact
                            path="/current-occupancy"
                            render={() => {
                              if (mainRoutes.includes("/current-occupancy")) {
                                return <CurrentOccupancy {...this.props} />;
                              } else {
                                return <Redirect to={{ pathname: "/dashboard" }} />;
                              }
                            }}
                          />
                          <Route
                            exact
                            path="/current-occupancy/:menu/:domain/:user/:epoch/:key"
                            render={() => {
                              if (mainRoutes.includes("/current-occupancy")) {
                                return <CurrentOccupancy {...this.props} />;
                              } else {
                                return <Redirect to={{ pathname: "/dashboard" }} />;
                              }
                            }}
                          />
                          <Route
                            exact
                            path="/add-unit-permit"
                            render={() => {
                              if (mainRoutes.includes("/add-unit-permit")) {
                                return <AddUnitPermit {...this.props} />;
                              } else {
                                return <Redirect to={{ pathname: "/dashboard" }} />;
                              }
                            }}
                          />
                          <Route
                            exact
                            path="/unit-occupancy"
                            render={() => {
                              if (mainRoutes.includes("/unit-occupancy")) {
                                return <ResidentOccupancy {...this.props} />;
                              } else {
                                return <Redirect to={{ pathname: "/dashboard" }} />;
                              }
                            }}
                          />
                          <Route
                            exact
                            path="/citation"
                            render={() => {
                              if (mainRoutes.includes("/citation")) {
                                return <Ticket {...this.props} />;
                              } else {
                                return <Redirect to={{ pathname: "/dashboard" }} />;
                              }
                            }}
                          />
                          <Route
                            exact
                            path="/passage-pass/:menu/:domain/:user/:epoch/:key"
                            render={() => {
                              if (mainRoutes.includes("/passage-pass")) {
                                return <PassagePass {...this.props} />;
                              } else {
                                return <Redirect to={{ pathname: "/dashboard" }} />;
                              }
                            }}
                          />
                          <Route
                            exact
                            path="/passage-pass"
                            render={() => {
                              if (mainRoutes.includes("/passage-pass")) {
                                return <PassagePass {...this.props} />;
                              } else {
                                return <Redirect to={{ pathname: "/dashboard" }} />;
                              }
                            }}
                          />
                          <Route
                            exact
                            path="/citation-list"
                            render={() => {
                              if (mainRoutes.includes("/citation-list")) {
                                return <TicketList {...this.props} />;
                              } else {
                                return <Redirect to={{ pathname: "/dashboard" }} />;
                              }
                            }}
                          />
                          <Route
                            exact
                            path="/citation-list/:menu/:domain/:user/:epoch/:key"
                            render={() => {
                              if (mainRoutes.includes("/citation-list")) {
                                return <TicketList {...this.props} />;
                              } else {
                                return <Redirect to={{ pathname: "/dashboard" }} />;
                              }
                            }}
                          />
                          <Route
                            path="/dispute"
                            render={() => {
                              if (mainRoutes.includes("/dispute")) {
                                return <Dispute {...this.props} />;
                              } else {
                                return <Redirect to={{ pathname: "/dashboard" }} />;
                              }
                            }}
                          />
                          <Route exact path="/privacy-policy" render={() => <Privacy {...this.props} history={history} />} />
                          <Route exact path="/privacy-policy-french" render={() => <PrivacyFrench {...this.props} history={history} />} />
                          <Route
                            exact
                            path="/audit-report"
                            render={() => {
                              if (mainRoutes.includes("/audit-report")) {
                                return <AuditReport {...this.props} />;
                              } else {
                                return <Redirect to={{ pathname: "/dashboard" }} />;
                              }
                            }}
                          />
                          <Route
                            exact
                            path="/audit-report/:menu/:domain/:user/:epoch/:key"
                            render={() => {
                              if (mainRoutes.includes("/audit-report")) {
                                return <AuditReport {...this.props} />;
                              } else {
                                return <Redirect to={{ pathname: "/dashboard" }} />;
                              }
                            }}
                          />
                          <Route exact path="/visitor-permit/:menu" render={() => <VisitorPermit {...this.props} />} />
                          <Route
                            exact
                            path="/report-dispenser"
                            render={() => {
                              if (mainRoutes.includes("/report-dispenser")) {
                                return <DispenserReport {...this.props} />;
                              } else {
                                return <Redirect to={{ pathname: "/dashboard" }} />;
                              }
                            }}
                          />
                          <Route
                            exact
                            path="/report-vend-control"
                            render={() => {
                              if (mainRoutes.includes("/report-vend-control")) {
                                return <VendControlReport {...this.props} />;
                              } else {
                                return <Redirect to={{ pathname: "/dashboard" }} />;
                              }
                            }}
                          />
                          <Route
                            exact
                            path="/report-vend-control/:menu/:domain/:user/:epoch/:key"
                            render={() => {
                              if (mainRoutes.includes("/report-vend-control")) {
                                return <VendControlReport {...this.props} />;
                              } else {
                                return <Redirect to={{ pathname: "/dashboard" }} />;
                              }
                            }}
                          />
                          <Route
                            exact
                            path="/report-validation"
                            render={() => {
                              if (mainRoutes.includes("/report-validation")) {
                                return <MerchantValidation {...this.props} />;
                              } else {
                                return <Redirect to={{ pathname: "/dashboard" }} />;
                              }
                            }}
                          />
                          <Route
                            exact
                            path="/report-rate-tracking"
                            render={() => {
                              if (mainRoutes.includes("/report-rate-tracking")) {
                                return <RateTracking {...this.props} />;
                              } else {
                                return <Redirect to={{ pathname: "/dashboard" }} />;
                              }
                            }}
                          />
                          <Route
                            exact
                            path="/refund"
                            render={() => {
                              if (mainRoutes.includes("/refund")) {
                                return <Refund {...this.props} />;
                              } else {
                                return <Redirect to={{ pathname: "/dashboard" }} />;
                              }
                            }}
                          />
                          <Route
                            exact
                            path="/report-invoice"
                            render={() => {
                              if (mainRoutes.includes("/report-invoice")) {
                                return <InvoiceReport {...this.props} />;
                              } else {
                                return <Redirect to={{ pathname: "/dashboard" }} />;
                              }
                            }}
                          />
                          <Route
                            exact
                            path="/invoice-details-report"
                            render={() => {
                              if (mainRoutes.includes("/invoice-details-report")) {
                                return <InvoiceDetails {...this.props} />;
                              } else {
                                return <Redirect to={{ pathname: "/dashboard" }} />;
                              }
                            }}
                          />
                          <Route
                            exact
                            path="/cashout"
                            render={() => {
                              if (mainRoutes.includes("/cashout")) {
                                return <CashoutReport {...this.props} />;
                              } else {
                                return <Redirect to={{ pathname: "/dashboard" }} />;
                              }
                            }}
                          />

                          <Route
                            exact
                            path="/battery-report"
                            render={() => {
                              if (mainRoutes.includes("/battery-report")) {
                                return <MeterBatteryReport {...this.props} />;
                              } else {
                                return <Redirect to={{ pathname: "/dashboard" }} />;
                              }
                            }}
                          />
                          <Route
                            exact
                            path="/length_of_stay_report"
                            render={() => {
                              if (mainRoutes.includes("/length_of_stay_report")) {
                                return <LengthOfStayReport {...this.props} />;
                              } else {
                                return <Redirect to={{ pathname: "/dashboard" }} />;
                              }
                            }}
                          />
                          <Route
                            exact
                            path="/meter_status_report"
                            render={() => {
                              if (mainRoutes.includes("/meter_status_report")) {
                                return <MeterStatusReport {...this.props} />;
                              } else {
                                return <Redirect to={{ pathname: "/dashboard" }} />;
                              }
                            }}
                          />
                          <Route
                            exact
                            path="/last_transaction_report"
                            render={() => {
                              if (mainRoutes.includes("/meter_status_report")) {
                                return <LastTransactionReport {...this.props} />;
                              } else {
                                return <Redirect to={{ pathname: "/dashboard" }} />;
                              }
                            }}
                          />
                          <Route
                            exact
                            path="/activity-logs-report"
                            render={() => {
                              if (mainRoutes.includes("/activity-logs-report")) {
                                return <ActivityLog {...this.props} />;
                              } else {
                                return <Redirect to={{ pathname: "/dashboard" }} />;
                              }
                            }}
                          />
                          <Route
                            exact
                            path="/report-transactions"
                            render={() => {
                              if (mainRoutes.includes("/report-transactions")) {
                                return <Transactions {...this.props} />;
                              } else {
                                return <Redirect to={{ pathname: "/dashboard" }} />;
                              }
                            }}
                          />
                          <Route
                            exact
                            path="/live-feed"
                            render={() => {
                              if (mainRoutes.includes("/live-feed")) {
                                return <LiveFeed {...this.props} />;
                              } else {
                                return <Redirect to={{ pathname: "/dashboard" }} />;
                              }
                            }}
                          />
                          <Route
                            exact
                            path="/live-feed/:menu/:domain/:user/:epoch/:key"
                            render={() => {
                              if (mainRoutes.includes("/live-feed")) {
                                return <LiveFeed {...this.props} />;
                              } else {
                                return <Redirect to={{ pathname: "/dashboard" }} />;
                              }
                            }}
                          />
                          <Route
                            exact
                            path="/smart-gate"
                            render={() => {
                              if (mainRoutes.includes("/smart-gate")) {
                                return <SmartGate {...this.props} />;
                              } else {
                                return <Redirect to={{ pathname: "/dashboard" }} />;
                              }
                            }}
                          />
                          <Route exact path="/smart-gate/:menu/:domain/:user/:epoch/:key" render={() => <SmartGate {...this.props} />} />
                          <Route
                            exact
                            path="/lot-sharing"
                            render={() => {
                              if (mainRoutes.includes("/lot-sharing")) {
                                return <LotSharing {...this.props} />;
                              } else {
                                return <Redirect to={{ pathname: "/dashboard" }} />;
                              }
                            }}
                          />
                          <Route
                            exact
                            path="/add-quick-permit"
                            render={() => {
                              if (mainRoutes.includes("/add-quick-permit")) {
                                return <AddQuickPermit {...this.props} />;
                              } else {
                                return <Redirect to={{ pathname: "/dashboard" }} />;
                              }
                            }}
                          />
                          <Route
                            exact
                            path="/add-quick-permit/:menu/:domain/:user/:epoch/:key"
                            render={() => {
                              if (mainRoutes.includes("/add-quick-permit")) {
                                return <AddQuickPermit {...this.props} />;
                              } else {
                                return <Redirect to={{ pathname: "/dashboard" }} />;
                              }
                            }}
                          />
                        </>
                      )}
                      {!(mainRoutes.indexOf(this.props.location.pathname) !== -1 || isValid) && (
                        <Route render={() => <Redirect to={{ pathname: "/dashboard" }} />} />
                      )}
                      {this.props.isSemiLogin && !(mainRoutes.indexOf(this.props.location.pathname) !== -1 || isValid) && <Redirect to="/unautorized" />}
                    </>
                  )}
                </div>
                {footerSize && (
                  <div className="footer-full-page">
                    <Footer />
                  </div>
                )}
                {!footerSize && <Footer />}
              </div>
            </>
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ SuperAdminDetails, UserProfileDetails, APIResponseDetails }) => {
  const { userModulePermissions, permissions: allPermissions } = SuperAdminDetails;
  const { user_type, property_id, permission_id, timezone } = UserProfileDetails;
  const { api_response, api_error_message, api_success_message } = APIResponseDetails;
  return {
    timezone,
    userModulePermissions,
    allPermissions,
    property_id,
    api_response,
    api_error_message,
    api_success_message,
    user_type,
    permission_id,
  };
};
const mapStateToDispatch = {
  getPermission: SuperAdminAction.getPermission,
};

export default connect(mapStateToProps, mapStateToDispatch)(AdminRoutes);
