import React, { Component } from "react";
import moment from "moment";
import ConfirmReservation from "../Modal/ConfirmReservation";
import { Button, Card, Form, Container, Row, Col, Table } from "react-bootstrap";
import AsyncSelect from "react-select/async";
import { connect } from "react-redux";
import Loader from "../Loader/Loader";
import { APIResponseActions, CurrentPermitActions, SuperAdminAction, PermitOptionsActions } from "../../Actions";
import CustomContentAlert, { regExForPhone, validations, validation_message, convertDateTimeToUtc, email_validation_message, isEmailValid } from "../helper";
import "react-perfect-scrollbar/dist/css/styles.css";
import CreditTable from "./CreditTable";
import isEqual from "lodash/isEqual";
import _i18n from "../Language/i18n";
import { Translation } from "react-i18next";
import { gettemplateDateInFrenchLanguage, gettimeSlotDateInFrenchLanguage } from "../language";

class VisitorPermit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      residentList_: [],
      defaultMenuIsOpen: false,
      currentCredits: [],
      usedCredits: [],
      allocatedCredits: [],
      usedAllocatedCredits: [],
      loader: false,
      bookingSlots: [],
      confirm_isopen: false,
      confirm_data: {},
      first_name: "",
      last_name: "",
      country_code: "",
      contact_number: "",
      plate_number: "",
      showBookingError: false,
      dayList: {},
      isOpen: false,
      showError: true,
      data_parked: {},
      credit: this.props.credits,
      suite_id: this.props.suite_id,
      propertyId: this.props.property_id,
      userDetails:
        this.props.UserProfileDetails && Object.keys(this.props.UserProfileDetails).length
          ? this.props.UserProfileDetails
          : this.props.semiAuthorizedUserProfile,
      user_type: this.props.user_type === undefined ? this.props.semiAuthorizedUserProfile?.user_type : this.props.user_type,
      availableSpots: this.props.available_spot,
      leftCredit: 0,
      guest_email: "",
      // creditLimit: 0,
      runningCredits: undefined,
      creditAllocated: 0,
      isFormValid: false,
      validFirstName: true,
      validLastName: true,
      validContactNumber: true,
      validPlateNumber: true,
      validSuite_id: true,
      appConfig: this.props.appConfig,
      show_alert: false,
      api_success_message: "",
      email: "",
      validEmail: true,
      emptying_form: false,
      hideMsg: false,
      credit_alert: false,
      credit_msg: "",
      isSubmitted: false,
      time_slot: [],
      date_slot: [],
      credit_30days_reports: [],
      permit_credit_group: [],
      credit_type: null,
      day_availablity: this.props.day_availablity,
      evening_availability: this.props.evening_availability,
      night_availability: this.props.night_availability,
      single_credit_availability: {},
      api_error_message: "",
      show_alert_on_error: true,
      property: this.props.property,
      template: "",
      guest_email_enabled: this.props.UserProfileDetails.guest_email_enabled,
      total_credit: 0,
      selected_suite_id: "",
      searchedSuiteId: "",
      isLoading: false,
      redirect_from: "",
      image: "",
      lpn: "",
      isPlateApproved: false,
      plateData: null,
      scan_time: 0,
      email: "",
      creditLimitMessage: "",
      showAsyncHead: false,
      phoneErrorMessage: "",
      isButtonDisable: true,
      multiplePermits: this.props.multiple_permits,
      isOpenDateList: false,
      country_code_list: [
        { id: 1, text: "+1", value: "+1" },
        { id: 2, text: "+91", value: "+91" },
        { id: 3, text: "+44", value: "+44" },
        { id: 4, text: "+61", value: "+61" },
      ],
      phone_options: [],
      selectDate: "",
      selectTime: "",
      selectTo: "",
      startTimeList: [],
      selectToData: [],
      unit_language: this.props?.selectedLang || this.props?.language,
    };
  }

  componentDidMount = async () => {
    if (this.state.user_type === "unit") {
      if (this.props.property === undefined) {
        await this.props.getCreditTemplateGroups(this.props.semiAuthorizedUserProfile?.property?.template_id);
      } else {
        await this.props.getCreditTemplateGroups(this.props.property?.template_id);
      }
      const { propertyId, availableSpots, credit, userDetails } = this.state;
      const suite_id = this.state.suite_id ? this.state.suite_id : this.props.semiAuthorizedUserProfile?.suite_id;
      this.setState({ loader: true, isOpenDateList: true }, async () => {
        if (propertyId === undefined) {
          await this.props.getCurrentPermits(this.props?.semiAuthorizedUserProfile?.property_id, availableSpots, credit, suite_id);
        } else {
          await this.props.getCurrentPermits(propertyId, availableSpots, credit, suite_id);
        }
        _i18n.changeLanguage(this.state.unit_language);
        this.setState(
          {
            dayList: this.props.data_parked ? this.props.data_parked : {},
            data_parked: this.props.data_parked ? this.props.data_parked : {},
            time_slot: this.props?.time_slot?.length ? this.props.time_slot : [],
            date_slot: this.props?.date_slot?.length ? this.props.date_slot : [],
            credit_used_30days: this.props?.credit_used_30days?.length ? this.props.credit_used_30days : "",
            credit_30days_reports: this.props?.credit_30days_reports?.length ? this.props.credit_30days_reports : [],
            credit_type: this.props.credit_type === undefined ? this.props?.semiAuthorizedUserProfile?.credit_type : this.props.credit_type,
            day_availablity: this.props.day_availablity,
            evening_availability: this.props.evening_availability,
            night_availability: this.props.night_availability,
            single_credit_availability: this.props?.single_credit_availability,
            template: this.props?.property?.credit_group,
            guest_email_enabled: this.props.UserProfileDetails.guest_email_enabled,
            multiplePermits: this.props.multiple_permits,
          },
          () => {
            const formatedTime = this.getCurrentTimeInHHMM(this.props.timezone);
            const timeToBeSelected = this.state.date_slot[0]?.timeslot.findIndex((item) => {
              return (
                    moment(item.time_from, "HH:mm").isAfter(moment(formatedTime, "HH:mm")) ||
                    moment(item.time_from, "HH:mm").isSame(moment(formatedTime, "HH:mm"))
              );
            });
            this.setState(
              {
                selectDate: this.state.date_slot[0]?.date || "",
                startTimeList: this.state.date_slot[0]?.timeslot || [],
                selectTime: timeToBeSelected !== -1 ? timeToBeSelected : "",
              },
              () => {
                const endDateOptions = this.getEndDateOptions(this.state.date_slot[0]?.date, this.state.selectTime);
                this.setState({
                  selectToData: endDateOptions,
                });
              },
            );
          },
        );
        this.props.setResidentCredits(userDetails);
        this.props.getResidents(userDetails);
        this.getResidentCreditSummary();
        this.setState({ loader: false, showError: this.props.api_response });
      });
      if (this.state.leftCredit <= 0 && this.props.permits_allowed_type !== 2 && !this.props.visitor_credit_payment) {
        this.setState({
          creditLimitMessage: "You don't have credit limit to add visitors",
        });
      }
    } else if (this.state.user_type === "admin" || this.state.user_type === "superadmin") {
      await this.props.InitializePermitAllowedType();
      if (this.props?.location.state) {
        const lpn = this.props.location.state.lpn;
        const redirect_from = this.props.location.state.redirect_from;
        const image = this.props.location.state.image;
        const email = this.props.location.state.email;
        const scan_time = this.props.location.state.scan_time;
        const isPlateApproved = this.props.location.state.isPlateApproved;
        const _plateData = this.props.location.state.data;
        const plateData = _plateData && Object.keys(_plateData).length ? _plateData : null;
        this.setState({
          plate_number: lpn,
          redirect_from: redirect_from,
          isPlateApproved: isPlateApproved,
          image: image,
          lpn: lpn,
          plateData: plateData,
          scan_time: scan_time,
          email: email,
          multiplePermits: this.props.multiple_permits,
        });
      }
    }
    const selectedPropertyName = this.state.property?.property_name;
    const selectedDomainName = this.state.property?.domain;
    await this.props.setDomainHeader(selectedPropertyName, selectedDomainName);
    await this.props.fetchResidentList();
    this.setState({ showAsyncHead: true });
    if (this.props.api_response) {
      this.setState(
        {
          residentList_: this.props.residentList,
          mobile_view_option: this.props?.mobile_view_option,
          is_optional: this.props?.is_optional,
        },
        () => {
          if (this.state.residentList_.length > 0) {
            this.filterResident();
          }
        },
      );
    }
    this.setState({
      credit: this.props.credits,
    });
    // return newList;
  };

  getValueOrDefalut = (value, defaultValue) => {
    return value >= 0 ? value : defaultValue;
  };

  getResidentCreditSummary = () => {
    this.setState({ usedAllocatedCredits: this.props.usedAllocatedCredits }, () => {
      let allocatedCredits = this.state.usedAllocatedCredits;
      if (allocatedCredits?.length) {
        allocatedCredits = allocatedCredits[0].cost;
      } else {
        allocatedCredits = 0;
      }
      const usedCredits = parseFloat(allocatedCredits);

      this.setState({ currentCredits: this.props.currentCredits }, () => {
        const allocatedCredits = this.state.currentCredits;
        let creditAllocated;
        if (allocatedCredits?.length) {
          creditAllocated = parseFloat(allocatedCredits[0].cost);
        } else {
          creditAllocated = 0;
        }
        let leftCredit;
        // let creditLimit = 0;
        if (this.state.credit_type === "single") {
          const credit_available = this.state?.single_credit_availability?.credit_available;
          leftCredit = credit_available;
          // creditLimit = credit;
        } else {
          // const dayValue = this.getValueOrDefalut(this.props.day, 0);
          // const eveningValue = this.getValueOrDefalut(this.props.evening, 0);
          // const nightValue = this.getValueOrDefalut(this.props.night, 0);

          // const credit = dayValue + eveningValue + nightValue;
          const credit_available = this.state.credit_30days_reports
            ? this.state.credit_30days_reports.map((item) => this.getValueOrDefalut(item.credit_available, 0)).reduce((prev, curr) => prev + curr, 0)
            : 0;
          leftCredit = credit_available;
          // creditLimit = credit;
        }
        this.setState({
          leftCredit: leftCredit,
          // creditLimit,
          runningCredits: usedCredits,
          creditAllocated,
        });
        this.setState({ usedCredits: this.props.usedCredits });
        this.setState({
          allocatedCredits: this.props.allocatedCredits,
        });
      });
    });
  };

  componentDidUpdate(prevProps) {
    if (prevProps.data_parked !== this.props.data_parked) {
      this.setState({
        data_parked: this.props.data_parked,
        dayList: this.props.data_parked,
        showError: this.props.api_response,
      });
    }
    if (prevProps.usedAllocatedCredits !== this.props.usedAllocatedCredits) {
      this.setState({
        usedAllocatedCredits: this.props.usedAllocatedCredits,
        showError: this.props.api_response,
      });
    }
    if (prevProps.currentCredits !== this.props.currentCredits) {
      this.setState({
        currentCredits: this.props.currentCredits,
        showError: this.props.api_response,
      });
    }
    if (prevProps.usedCredits !== this.props.usedCredits) {
      this.setState({
        usedCredits: this.props.usedCredits,
        showError: this.props.api_response,
      });
    }
    if (prevProps.allocatedCredits !== this.props.allocatedCredits) {
      this.setState({
        allocatedCredits: this.props.allocatedCredits,
        showError: this.props.api_response,
      });
    }
    if (!isEqual(prevProps.credit_30days_reports, this.props.credit_30days_reports)) {
      this.setState({
        credit_30days_reports: this.props.credit_30days_reports,
        showError: this.props.api_response,
      });
    }
    if (!isEqual(prevProps.single_credit_availability, this.props.single_credit_availability)) {
      this.setState({
        single_credit_availability: this.props.single_credit_availability,
        showError: this.props.api_response,
      });
    }
    if (!isEqual(prevProps.credit_used_30days, this.props.credit_used_30days)) {
      this.setState({
        credit_used_30days: this.props.credit_used_30days,
        showError: this.props.api_response,
      });
    }
    if (!isEqual(prevProps.UserProfileDetails, this.props.UserProfileDetails)) {
      this.setState({
        userDetails: this.props.UserProfileDetails,
        showError: this.props.api_response,
      });
    }
    if (prevProps.selectedLang !== this.props.selectedLang) {
      this.setState({
        unit_language: this.props.selectedLang,
      });
      _i18n.changeLanguage(this.props.selectedLang);
    }
  }

  handleCheckBox = (keys, columnIndex, item) => {
    const selectedId = +item.id;
    let limit = 0;
    let tempId;
    const { dayList, leftCredit } = this.state;
    dayList[keys][columnIndex].isChecked = !dayList[keys][columnIndex].isChecked;
    const _credit = this.state.credit;
    let credit = _credit === undefined ? this.props.semiAuthorizedUserProfile?.credits : _credit;

    console.log("handleCheckBox item is checked on grid::::11111", item.isChecked);
    if (item.isChecked) {
      console.log("if called:::::::11111");
      // Property leve flag this.props.visitor_credit_payment = true then we need allow to checkbox
      const globalCredit = this.props.globaleCredit === undefined ? this.props.semiAuthorizedUserProfile?.credits : this.props.globaleCredit;
      console.log("global credit 11111111111", globalCredit, +credit);
      console.log("left credit:::::::", +credit, leftCredit, +credit >= leftCredit, this.props.permits_allowed_type);
      if (globalCredit === +credit) {
        if (+credit >= leftCredit && this.props.permits_allowed_type !== 2 && !this.props.visitor_credit_payment) {
          console.log("if inside called::::", limit);
          limit = selectedId + leftCredit - 1;
        } else {
          limit = selectedId + credit - 1;
        }

        Object.keys(dayList).forEach((keys) => {
          dayList[keys].forEach((value) => {
            const valueId = +value.id;
            if (valueId === selectedId) {
              credit--;
              value.isChecked = true;
              value.isDisabled = false;
              this.setState({ hideMsg: true });
              const { startcurrent_time, endcurrent_time } = convertDateTimeToUtc(this.props.timezone, item.startDate, item.endDate);
              const permitGroup = {
                group_name: item.group_name,
                timeslot_name: item.alias,
                begin_time: item.startDate,
                expire_time: item.endDate,
                begin_time_utc: startcurrent_time,
                expire_time_utc: endcurrent_time,
                credits: item.credits,
                lpn_restriction: item.lpn_restriction,
                id: item.id,
              };
              const joined = this.state.permit_credit_group.concat(permitGroup);
              this.setState({ permit_credit_group: joined }, () => {
                console.log("permit_credit_group1::::::::::::::::::::::::::::", this.state.permit_credit_group);
              });
            }
            if (valueId > selectedId && valueId <= limit && value.isBooked) {
              tempId = valueId;
            }
            if (this.props.permits_allowed_type !== 2 && !this.props.visitor_credit_payment) {
              if (valueId < selectedId || valueId > (tempId || limit)) {
                value.isChecked = false;
                value.isDisabled = true;
              }
            } else {
              if (valueId < selectedId) {
                value.isChecked = false;
                value.isDisabled = true;
              }
            }
          });
        });
      } else {
        limit = selectedId;
        console.log("limit::::::::::leftCredit", limit, leftCredit);
        Object.keys(dayList).forEach((dayListKeys) => {
          dayList[dayListKeys].forEach((value) => {
            const valueId = +value.id;
            if (valueId <= limit && !value.isChecked && !value.isDisabled && !value.isBooked) {
              credit--;
              value.isChecked = true;
              value.isDisabled = false;
              this.setState({ hideMsg: true });
              const { startcurrent_time, endcurrent_time } = convertDateTimeToUtc(this.props.timezone, item.startDate, item.endDate);

              const permitGroup = {
                group_name: value.group_name,
                timeslot_name: value.alias,
                begin_time: value.startDate,
                expire_time: value.endDate,
                begin_time_utc: startcurrent_time,
                expire_time_utc: endcurrent_time,
                credits: value.credits,
                lpn_restriction: value.lpn_restriction,
                id: value.id,
              };

              const permit_credit_group = this.state.permit_credit_group;
              const joined = permit_credit_group.push(permitGroup);
              this.setState({ permit_credit_group: joined }, () => {
                console.log("permit_credit_group2::::::::::::::::::::::::::::", this.state.permit_credit_group);
              });
            }
            if (valueId === selectedId) {
              credit--;
              value.isChecked = true;
              value.isDisabled = false;
              this.setState({ hideMsg: true });
              const { startcurrent_time, endcurrent_time } = convertDateTimeToUtc(this.props.timezone, item.startDate, item.endDate);

              const permitGroup = {
                group_name: value.group_name,
                timeslot_name: value.alias,
                begin_time: value.startDate,
                expire_time: value.endDate,
                begin_time_utc: startcurrent_time,
                expire_time_utc: endcurrent_time,
                credits: value.credits,
                lpn_restriction: value.lpn_restriction,
                id: value.id,
              };
              const joined = this.state.permit_credit_group.concat(permitGroup);
              this.setState({ permit_credit_group: joined }, () => {
                console.log("permit_credit_group3::::::::::::::::::::::::::::", this.state.permit_credit_group);
              });
            }
          });
        });
      }
    } else {
      console.log("else called::::::::");
      if (!item.isChecked) {
        const id = item.id;
        const checkedSlots = JSON.parse(JSON.stringify(this.state.permit_credit_group));
        checkedSlots.pop();
        const clickedIdObj = checkedSlots.find((slot) => slot.id === id);
        const isIdExist = !!(clickedIdObj && Object.keys(clickedIdObj).length);

        if (!isIdExist) {
          const updated_permit_credit_group = (this.state.permit_credit_group ?? []).filter((el) => el.id !== id);

          this.setState({
            permit_credit_group: updated_permit_credit_group,
          });
        }
      }

      this.setState({ hideMsg: true });
      const checkNextSelected = []; // why?
      const checkIsSelectedExist = [];
      Object.keys(dayList).forEach((_keys) => {
        dayList[_keys].forEach((_item) => {
          const itemId = +_item.id;
          if (itemId > selectedId && _item.isChecked) {
            checkNextSelected.push(_item);
          } else if (_item.isChecked) {
            this.setState({ hideMsg: true });
            checkIsSelectedExist.push(_item);
          }
        });
      });
      if (checkNextSelected.length > 0) {
        // when does it run?
        item.isChecked = true;
        return false;
      } else {
        credit++;
        tempId = undefined;
      }

      if (checkIsSelectedExist.length === 0) {
        Object.keys(dayList).forEach((key) => {
          dayList[key].forEach((value) => {
            value.isDisabled = false;
            this.setState({ hideMsg: true });
          });
        });
      }
    }
    this.validateVisitorPermitForm();
    this.setState({ dayList, credit });
  };

  handleCreateReservation = async () => {
    console.log("handle grid called:::::111111");
    let { dayList, showBookingError, first_name, last_name, plate_number, country_code, contact_number, bookingSlots, leftCredit, phone_options, guest_email } =
          this.state;
    if (leftCredit > 0 || this.props.permits_allowed_type === 2 || (leftCredit <= 0 && this.props.visitor_credit_payment)) {
      bookingSlots = [];
      Object.keys(dayList).forEach((keys) => {
        dayList[keys].forEach((value) => {
          if (value.isChecked) {
            bookingSlots.push(value);
          } else {
            this.setState({ hideMsg: false });
          }
        });
      });
      setTimeout(() => {
        Object.keys(dayList).forEach((keys) => {
          dayList[keys].forEach((value) => {
            value.isDisabled = false;
            if (value.isChecked) {
              value.isChecked = !value.isChecked;
            }
          });
        });
      }, 1000);
      if (bookingSlots.length === 0) {
        showBookingError = true;
        this.setState({ showBookingError });
      } else {
        showBookingError = false;
        this.setState({ showBookingError });
      }

      console.log("Booking slot:::::::", bookingSlots, bookingSlots.length);

      if (bookingSlots.length) {
        const { permit_credit_group } = this.state;
        const displayStartTime = moment(permit_credit_group[0]?.begin_time).format("lll");
        const displayEndTime = moment(permit_credit_group[permit_credit_group.length - 1]?.expire_time).format("lll");
        if (this.state.credit_type === "single") {
          const total_credit = this.state.permit_credit_group
            .reduce((acc, el) => {
              return acc + parseFloat(el.credits);
            }, 0)
            .toFixed(2);
          console.log("Total Credit::::", total_credit, leftCredit, total_credit <= leftCredit);
          if (total_credit <= leftCredit || this.props.permits_allowed_type === 2 || this.props.visitor_credit_payment) {
            const propertyId = this.state.propertyId ? this.state.propertyId : this.props?.semiAuthorizedUserProfile?.property_id;
            const suite_id_tobe_passed = await this.findSuiteIdForCreditCard();
            if (leftCredit < total_credit && this.props.visitor_credit_payment && this.props.permits_allowed_type !== 2) {
              await this.findCreditCardDetailsForUnit(propertyId, suite_id_tobe_passed);
            }

            const data = {
              title: "Confirm Visitor Reservation",
              firstName: first_name,
              lastName: last_name,
              plateNumber: plate_number,
              guest_email: guest_email,
              country_code: country_code,
              phone: contact_number,
              phone_options: phone_options,
              startTime: displayStartTime,
              endTime: displayEndTime,
              usedCredits: total_credit,
              multiplePermits: this.state.multiplePermits,
              leftCredit: leftCredit,
              permits_allowed_type: this.props.permits_allowed_type,
              visitor_credit_payment: this.props.visitor_credit_payment,
              propertyId: propertyId,
              suite_id: suite_id_tobe_passed,
              unit_language: this.state.unit_language,
              // cardDetailData: this.props.cardDetails
            };
            this.setState({
              confirm_data: data,
              confirm_isopen: true,
              bookingSlots,
              total_credit: total_credit,
            });
          } else {
            this.setState({
              credit_alert: true,
              credit_msg: "please check your credits and select the slots again",
              credit: this.props.credits,
              permit_credit_group: [],
            });
            Object.keys(dayList).forEach((keys) => {
              dayList[keys].forEach((value) => {
                value.isDisabled = false;
                if (value.isChecked) {
                  value.isChecked = !value.isChecked;
                }
              });
            });
            setTimeout(() => {
              this.setState({
                credit_alert: false,
                credit_msg: "",
              });
            }, 4000);
          }
        } else {
          let allCreditObj = {};
          let totalCredit = 0;
          this.props.templateCreditGroup.map((item) => {
            const tempCredit = this.state.permit_credit_group.reduce((sum, el) => {
              if (el.group_name === item) {
                return sum + parseFloat(el.credits);
              } else {
                return sum;
              }
            }, 0);
            allCreditObj = {
              ...allCreditObj,
              [item]: tempCredit,
            };
            totalCredit = totalCredit + tempCredit;
            console.log("totalCredit else", totalCredit);
            return item;
          });

          const creditValidityArray = [];
          (this.state.credit_30days_reports || []).forEach((item) => {
            if (allCreditObj[item.slot] <= (item.credit_available < 0 ? -item.credit_available : item.credit_available)) {
              creditValidityArray.push(true);
            } else {
              creditValidityArray.push(false);
            }
          });
          if (!creditValidityArray.includes(false) || this.props.permits_allowed_type === 2 || this.props.visitor_credit_payment) {
            const propertyId = this.state.propertyId ? this.state.propertyId : this.props?.semiAuthorizedUserProfile?.property_id;
            const suite_id_tobe_passed = await this.findSuiteIdForCreditCard();

            if (leftCredit < totalCredit && this.props.visitor_credit_payment && this.props.permits_allowed_type !== 2) {
              await this.findCreditCardDetailsForUnit(propertyId, suite_id_tobe_passed);
            }
            const data = {
              title: "Confirm Visitor Reservation",
              firstName: first_name,
              lastName: last_name,
              plateNumber: plate_number,
              country_code: country_code,
              guest_email: guest_email,
              phone_options: phone_options,
              phone: contact_number,
              startTime: displayStartTime,
              endTime: displayEndTime,
              usedCredits: totalCredit,
              multiplePermits: this.state.multiplePermits,
              leftCredit: leftCredit,
              permits_allowed_type: this.props.permits_allowed_type,
              visitor_credit_payment: this.props.visitor_credit_payment,
              propertyId: propertyId,
              suite_id: suite_id_tobe_passed,
              unit_language: this.state.unit_language,
              // cardDetailData: this.props.cardDetails
            };
            this.setState({
              confirm_data: data,
              confirm_isopen: true,
              bookingSlots,
              total_credit: totalCredit,
            });
          } else {
            this.setState({
              credit_alert: true,
              credit_msg: "please check your credits and select the slots again",
              credit: this.props.credits,
              permit_credit_group: [],
            });
            Object.keys(dayList).forEach((keys) => {
              dayList[keys].forEach((value) => {
                value.isDisabled = false;
                if (value.isChecked) {
                  value.isChecked = !value.isChecked;
                }
              });
            });
            setTimeout(() => {
              this.setState({
                credit_alert: false,
                credit_msg: "",
              });
            }, 4000);
          }
        }
      }
    }
  };

  findSuiteIdForCreditCard = () => {
    if (this.state.user_type === "unit") {
      const suite_id = this.state.suite_id ? this.state.suite_id : this.props.semiAuthorizedUserProfile?.suite_id;
      return suite_id;
    }
    if (this.state.user_type === "admin" || this.state.user_type === "superadmin") {
      const { selected_suite_id: suite_id } = this.state;
      return suite_id;
    }
  };

  handleCreateReservationForMobile = async () => {
    const {
      first_name,
      last_name,
      plate_number,
      guest_email,
      country_code,
      contact_number,
      leftCredit,
      phone_options,
      selectDate,
      selectTime,
      selectTo,
      selectToData,
      startTimeList,
    } = this.state;

    if (!selectDate || !selectTime.toString() || !selectTo) {
      this.setState({
        isMobileFormValid: false,
        isFormValid: false,
      });
    } else {
      if (leftCredit > 0 || this.props.permits_allowed_type === 2 || (leftCredit <= 0 && this.props.visitor_credit_payment)) {
        const startDate = selectDate + " " + startTimeList[selectTime]?.time_from;
        const endDate = selectToData[selectTo]?.slotDate + " " + selectToData[selectTo]?.time_to;
        const displayStartTime = moment(new Date(startDate)).format("lll");
        const displayEndTime = moment(new Date(endDate)).format("lll");
        const permit_credit_group_data = [];
        console.log("selectToData.............", selectToData.slice(0, parseInt(selectTo) + 1));
        const sumOfCredits = selectToData
          .slice(0, parseInt(selectTo) + 1)
          .map((item) => item.credits)
          .reduce((a, b) => a + b, 0);
        const bookingSlots_ = selectToData.slice(0, parseInt(selectTo) + 1).map((bookingSlot) => {
          const start = moment(`${bookingSlot.startDate} ${bookingSlot.time_from}`, "YYYY-MM-DD HH:mm");
          const end = moment(`${bookingSlot.slotDate} ${bookingSlot.time_to}`, "YYYY-MM-DD HH:mm");
          const { startcurrent_time, endcurrent_time } = convertDateTimeToUtc(this.props.timezone, start, end);
          permit_credit_group_data.push({
            credits: bookingSlot.credits,
            id: bookingSlot.order_id,
            group_name: bookingSlot.credit_group,
            timeslot_name: bookingSlot.alias,
            lpn_restriction: bookingSlot.lpn_restriction,
            begin_time: start,
            expire_time: end,
            begin_time_utc: startcurrent_time,
            expire_time_utc: endcurrent_time,
          });
          return {
            ...bookingSlot,
            endDate: end,
            startDate: start,
            group_name: bookingSlot.credit_group,
            timeslot_name: bookingSlot.alias,
          };
        });
        let allCreditObj = {};
        let totalCredit = 0;
        (this.props.templateCreditGroup ?? []).map((item) => {
          const tempCredit = permit_credit_group_data.reduce((sum, el) => {
            if (el.group_name === item) {
              return sum + parseFloat(el.credits);
            } else {
              return sum;
            }
          }, 0);
          allCreditObj = {
            ...allCreditObj,
            [item]: tempCredit,
          };
          totalCredit = totalCredit + tempCredit;
          return item;
        });
        console.log("allCreditObj.......allCreditObj..", allCreditObj);
        const creditValidityArray = [];
        (this.state.credit_30days_reports || []).forEach((item) => {
          if (allCreditObj[item.slot] <= (item.credit_available < 0 ? -item.credit_available : item.credit_available)) {
            creditValidityArray.push(true);
          } else {
            creditValidityArray.push(false);
          }
        });
        console.log("creditValidityArray.........", creditValidityArray);
        if (
          (this.state?.credit_type === "single" && sumOfCredits <= leftCredit) ||
          (this.state?.credit_type !== "single" && !creditValidityArray.includes(false)) ||
          this.props.visitor_credit_payment
        ) {
          const propertyId = this.state.propertyId ? this.state.propertyId : this.props?.semiAuthorizedUserProfile?.property_id;
          const suite_id_tobe_passed = await this.findSuiteIdForCreditCard();

          if (leftCredit < sumOfCredits && this.props.visitor_credit_payment && this.props.permits_allowed_type !== 2) {
            await this.findCreditCardDetailsForUnit(propertyId, suite_id_tobe_passed);
          }
          const data = {
            title: "Confirm Visitor Reservation",
            firstName: first_name,
            lastName: last_name,
            plateNumber: plate_number,
            country_code: country_code,
            phone_options: phone_options,
            phone: contact_number,
            guest_email:guest_email,
            startTime: displayStartTime,
            endTime: displayEndTime,
            usedCredits: sumOfCredits,
            multiplePermits: this.state.multiplePermits,
            leftCredit: leftCredit,
            permits_allowed_type: this.props.permits_allowed_type,
            visitor_credit_payment: this.props.visitor_credit_payment,
            propertyId: propertyId,
            suite_id: suite_id_tobe_passed,
            unit_language: this.state.unit_language,
            // cardDetailData: this.props.cardDetails
          };
          this.setState({
            confirm_data: data,
            confirm_isopen: true,
            total_credit: sumOfCredits,
            bookingSlots: bookingSlots_,
            permit_credit_group: permit_credit_group_data,
          });
        } else {
          this.setState({
            credit_alert: true,
            credit_msg: "please check your credits and select the slots again",
            credit: this.props.credits,
            permit_credit_group: [],
          });
          setTimeout(() => {
            this.setState({
              credit_alert: false,
              credit_msg: "",
            });
          }, 4000);
        }
      }
      this.setState({
        isMobileFormValid: true,
      });
    }
  };

  isMultiplePhoneValid = () => {
    const { phone_options } = this.state;

    const validPhone = phone_options?.map((options) => {
      if (options.phone?.trim().length > 10 || options.phone?.trim().length < 10 || options.phone?.trim().length === 0) {
        return !(options.phone.trim().length > 10 || options.phone.trim().length < 10 || options.phone.trim().length === 0);
      }
      return true;
    });
    return !validPhone.includes(false);
  };

  handleChange = ({ target }) => {
    const prevstate = this.state.contact_number;
    this.setState({ [target.name]: target.value, showBookingError: false }, async () => {
      if (this.state.contact_number.trim().length) {
        this.validateContactNumber();
      }
      if (target.name === "contact_number" && this.state.contact_number.trim().length) {
        if (regExForPhone.test(this.state.contact_number)) {
          this.setState({ contact_number: target.value });
        } else {
          this.setState({ contact_number: prevstate });
        }
      }
      this.validateVisitorPermitForm();
      switch (target.name) {
        case "contact_number": {
          if (this.state.contact_number.trim().length) {
            if (regExForPhone.test(this.state.contact_number)) {
              this.setState({ contact_number: target.value });
            } else {
              this.setState({ contact_number: prevstate });
            }
          } else {
            const CN = validations(target.name, "contact_number", this.state.contact_number);
            this.setState({
              contact_number: CN.value,
              validContactNumber: CN.validator,
            });
          }
          break;
        }
        case "country_code": {
          this.setState({
            country_code: target.value.replace(/[a-zA-Z]/, ""),
          });
          break;
        }
        case "first_name": {
          const FN = validations(target.name, "first_name", this.state.first_name);
          this.setState({
            first_name: FN.value,
            validFirstName: FN.validator,
          });
          break;
        }
        case "last_name": {
          const LN = validations(target.name, "last_name", this.state.last_name);
          this.setState({
            last_name: LN.value,
            validLastName: LN.validator,
          });
          break;
        }
        case "plate_number": {
          const PN = validations(target.name, "plate_number", this.state.plate_number);
          this.setState({
            plate_number: PN.value.toUpperCase().replace(/[^a-zA-Z0-9]/g, ""),
            validPlateNumber: PN.validator,
          });
          break;
        }
        case "suite_id": {
          const SI = validations(target.name, "suite_id", this.state.suite_id);
          this.setState({
            suite_id: SI.value,
            validSuite_id: SI.validator,
          });
          break;
        }
      }
    });
  };

  validateVisitorPermitForm = () => {
    let isPhoneReq = true;
    isPhoneReq = this.state.is_optional ? true : this.state.contact_number.trim().length;
    const isfirstNameReq = this.state.is_optional ? true : this.state.first_name.trim().length;
    const isLastNameReq = this.state.is_optional ? true : this.state.last_name.trim().length;
    const validateNumber = this.state.is_optional ? true : this.validateContactNumber();
    if (this.state.user_type !== "unit") {
      if (
        isfirstNameReq &&
        isLastNameReq &&
        isPhoneReq &&
        this.state.plate_number.trim().length &&
        this.state.selected_suite_id.trim().length &&
        validateNumber &&
        this.isMultiplePhoneValid()
      ) {
        this.setState({
          isFormValid: true,
        });
      } else {
        this.setState({
          isFormValid: false,
        });
      }
    } else {
      if (isfirstNameReq && isLastNameReq && isPhoneReq && this.state.plate_number.trim().length && validateNumber && this.isMultiplePhoneValid()) {
        this.setState({
          isFormValid: true,
        });
      } else {
        this.setState({
          isFormValid: false,
        });
      }
    }
  };

  validateContactNumber = () => {
    const { contact_number } = this.state;
    const regExForContactNumber = regExForPhone;
    const isContactNumberValidate = regExForContactNumber.test(String(contact_number));
    if (isContactNumberValidate) {
      if ((contact_number && contact_number.length >= 11) || (contact_number && contact_number.length < 10)) {
        this.setState({
          phoneErrorMessage: <Translation>{(t) => <>{t("Please enter valid phone number")}</>}</Translation>,
        });
        return false;
      } else {
        this.setState({
          phoneErrorMessage: "",
        });
        return true;
      }
    } else {
      return false;
    }
  };

  handleToogle = () => {
    this.setState({
      confirm_isopen: !this.state.confirm_isopen,
      credit: this.props.credits,
      permit_credit_group: [],
    });
  };

  findCreditCardDetailsForUnit = async (propertyId, suite_id) => {
    await this.getCreditCardDetails(propertyId, suite_id);
  };

  getCreditCardDetails = async (property_id, suite_id) => {
    this.props.getCardDetails(property_id, suite_id);
  };

  getCreditsDetails = (suite_id, propertyId) => {
    const { credit } = this.props;
    this.props.getCurrentPermits(propertyId, this.state.availableSpots, credit, suite_id);
  };

  handleSubmit = async (card_data) => {
    const current_credit = this.state.leftCredit - this.state.total_credit;
    const newAllocated = this.state.creditAllocated + this.state.total_credit;
    if (card_data) {
      const card_expiry_ = moment(new Date(card_data.card_expiry)).format("YYYY-MM-DD hh:mm:ss");
      card_data.card_expiry = card_expiry_;
    }
    this.setState(
      {
        loader: true,
        emptying_form: true,
        isFormValid: false,
        leftCredit: current_credit,
        creditAllocated: newAllocated,
        isSubmitted: true,
        isButtonDisable: false,
      },
      async () => {
        const timezone = this.props?.timezone ? this.props.timezone : this.props.semiAuthorizedUserProfile?.timezone;
        await this.props.AddPermits(
          this.state.bookingSlots,
          this.state.confirm_data,
          this.state.userDetails,
          this.state.permit_credit_group,
          this.state.credit_type,
          timezone,
          card_data,
        );

        this.handleCreditCard();
        this.handleVisitorApiResponse();
        this.getResidentCreditSummary();
        this.handleToogle();
        setTimeout(() => {
          const { redirect_from } = this.state;
          this.setState({
            show_alert: false,
            show_alert_on_error: true,
            isButtonDisable: true,
            api_error_message: "",
            showError: false,
          });

          if (redirect_from === "plate-check") {
            const history = require("../../history").default;
            history.push({
              pathname: `/plate-check`,
              state: {
                image: this.state.image,
                lpn: this.state.lpn,
                email: this.state.email,
                scan_time: this.state.scan_time,
                isPlateApproved: this.props.api_response,
              },
            });
          }
        }, 6000);
      },
    );
  };

  handleCreditCard = () => {
    const propertyId = this.state.propertyId ? this.state.propertyId : this.props?.semiAuthorizedUserProfile?.property_id;
    if (this.state.user_type === "unit") {
      const suite_id = this.state.suite_id ? this.state.suite_id : this.props.semiAuthorizedUserProfile?.suite_id;
      this.getCreditsDetails(suite_id, propertyId);
    }
    if (this.state.user_type === "admin" || this.state.user_type === "superadmin") {
      const { selected_suite_id: suite_id } = this.state;
      this.getCreditsDetails(suite_id, propertyId);
    }
  };

  handleInputSelect = async (e) => {
    this.setState(
      {
        selected_suite_id: e.value,
        selectedValue: {
          value: e.value,
          label: e.label,
        },
        isOpenDateList: true,
        credit: this.props.credits,
        loader: true,
      },
      async () => {
        const { selected_suite_id, propertyId, availableSpots, credit, userDetails } = this.state;
        const newUserDetails = {
          ...userDetails,
          suite_id: selected_suite_id,
        };
        this.setState({
          userDetails: newUserDetails,
        });
        if (
          this.state.first_name.trim().length &&
          this.state.last_name.trim().length &&
          this.state.contact_number.trim().length &&
          this.state.plate_number.trim().length &&
          this.state.selected_suite_id.trim().length &&
          this.validateContactNumber()
        ) {
          this.setState({
            isFormValid: true,
          });
        } else {
          this.setState({
            isFormValid: false,
          });
        }
        if (propertyId === undefined) {
          await this.props.getCurrentPermits(this.props?.semiAuthorizedUserProfile?.property_id, availableSpots, credit, selected_suite_id);
        } else {
          await this.props.getCurrentPermits(propertyId, availableSpots, credit, selected_suite_id);
        }
        const credit_type = this.props.credit_type === undefined ? this.props?.semiAuthorizedUserProfile?.credit_type : this.props.credit_type;

        this.setState(
          {
            dayList: this.props.data_parked ? this.props.data_parked : {},
            data_parked: this.props.data_parked ? this.props.data_parked : {},
            time_slot: this.props?.time_slot?.length ? this.props.time_slot : [],
            date_slot: this.props?.date_slot?.length ? this.props.date_slot : [],
            credit_used_30days: this.props?.credit_used_30days?.length ? this.props.credit_used_30days : "",
            credit_30days_reports: this.props?.credit_30days_reports?.length ? this.props.credit_30days_reports : [],
            credit_type: credit_type,
            single_credit_availability: this.props?.single_credit_availability,
            template: this.props?.property?.credit_group,
            multiplePermits: this.props.multiple_permits,
          },
          async () => {
            if (this.props.property === undefined) {
              await this.props.getCreditTemplateGroups(this.props.semiAuthorizedUserProfile?.property?.template_id);
            } else {
              await this.props.getCreditTemplateGroups(this.props.property?.template_id);
            }
            let day_availablity;
            let night_availability;
            let evening_availability;
            const formatedTime = this.getCurrentTimeInHHMM(this.props.timezone);
            const timeToBeSelected = this.state.date_slot[0]?.timeslot?.findIndex((item) => {
              return (
                moment(item.time_from, "HH:mm").isAfter(moment(formatedTime, "HH:mm")) || moment(item.time_from, "HH:mm").isSame(moment(formatedTime, "HH:mm"))
              );
            });
            this.setState(
              {
                day_availablity: day_availablity,
                night_availability: night_availability,
                evening_availability: evening_availability,
                selectDate: this.state.date_slot[0]?.date || "",
                startTimeList: this.state.date_slot[0]?.timeslot || [],
                selectTime: timeToBeSelected !== -1 ? timeToBeSelected : "",
              },
              () => {
                const endDateOptions = this.getEndDateOptions(this.state.date_slot[0]?.date, this.state.selectTime);
                this.setState({
                  selectToData: endDateOptions,
                });
              },
            );
          },
        );
        this.props.setResidentCredits(userDetails);
        this.props.getResidents(userDetails);
        this.getResidentCreditSummary();
        if (this.state.leftCredit <= 0 && this.props.permits_allowed_type !== 2 && !this.props.visitor_credit_payment) {
          this.setState({
            creditLimitMessage: "You don't have credit limit to add visitors",
          });
        }
        this.setState({
          loader: false,
        });
      },
    );
  };

  handleVisitorApiResponse = () => {
    if (this.props.api_response) {
      this.setState({
        loader: false,
        showError: this.props.api_response,
        show_alert: true,
        api_success_message: this.props.api_success_message,
        permit_credit_group: [],
        first_name: "",
        last_name: "",
        plate_number: this.props.location?.state && this.props.location?.state.lpn ? this.props.location?.state.lpn : "",
        country_code: "",
        contact_number: "",
        selectedValue: {},
        phone_options: [],
        selectDate: "",
        selectTime: "",
        selectTo: "",
      });
      this.handleAlertMessage();
    } else {
      this.setState({
        loader: false,
        showError: this.props.api_response,
        api_error_message: this.props.api_error_message,
        show_alert_on_error: this.props.api_response,
        permit_credit_group: [],
      });
    }
  };

  handleAlertMessage = () => {
    setTimeout(() => {
      this.setState({
        show_alert: false,
        api_success_message: "",
        showError: "",
      });
      if (this.state.user_type === "unit") {
        this.props.getTimeSlot(this.state.suite_id);
        this.setState({
          time_slot: this.props?.time_slot?.length ? this.props.time_slot : [],
          date_slot: this.props?.date_slot?.length ? this.props.date_slot : [],
        });
      }
    }, 1000);
  };

  getCurrentTimeInHHMM = (timezone) => {
    /// Specify the target timezone
    const targetTimezone = timezone;
    // Get the current date and time in the target timezone
    const currentTime = new Date().toLocaleTimeString("en-US", {
      timeZone: targetTimezone,
      timeStyle: "short",
      hour12: false,
    });
    // Extract the hours and minutes
    const [hours, minutes] = currentTime.split(":");
    // Format the time in HH:MM format
    const formattedTime = `${hours.padStart(2, "0")}:${minutes}`;
    // Output the formatted time
    return formattedTime;
  };

  filterResident = (selected_suite_id) => {
    if (selected_suite_id) {
      const searchResidentList = this.state.residentList_.filter((selectResident) => {
        return selectResident.suite_id.toLowerCase().includes(selected_suite_id.toLowerCase());
      });

      return (
        searchResidentList?.length &&
        searchResidentList.map((item) => {
          return {
            value: item.suite_id,
            label: item.suite_id,
          };
        })
      );
    } else {
      if (this.state.residentList_?.length) {
        return this.state.residentList_.map((item) => {
          return {
            value: item.suite_id,
            label: item.suite_id,
          };
        });
      } else {
        return [];
      }
    }
  };

  promiseOptions = async (inputValue) =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve(this.filterResident(inputValue));
      }, 800);
    });

  visitorDelete = async (e) => {
    const confirmation = window.confirm("Are you sure you want to deactivate this permit?");
    if (confirmation) {
      await this.props.visitorPermitDelete(e);
      const { propertyId, availableSpots, credit, selected_suite_id } = this.state;
      let suite_id = selected_suite_id;
      if (this.props.user_type === "unit") {
        suite_id = this.props.suite_id;
      }
      if (this.props.api_response) {
        this.setState({
          show_alert: true,
          api_success_message: this.props.api_success_message,
        });
        setTimeout(() => {
          this.setState({
            show_alert: false,
            api_success_message: "",
          });
        }, 4000);
      }
      if (propertyId === undefined) {
        await this.props.getCurrentPermits(this.props?.semiAuthorizedUserProfile?.property_id, availableSpots, credit, suite_id);
      } else {
        await this.props.getCurrentPermits(propertyId, availableSpots, credit, suite_id);
      }
      if (this.props.api_response) {
        this.setState({
          single_credit_availability: this.props?.single_credit_availability,
        });
        this.getResidentCreditSummary();
      }
    }
  };

  findGuestData = async () => {
    const { country_code, contact_number, selected_suite_id, propertyId } = this.state;
    if (contact_number) {
      const data = {
        country_code: country_code || "+1",
        contact_number,
        suite_id: selected_suite_id || this.state.suite_id,
        propertyId,
      };
      await this.props.GetGuestData(data);
      if (this.props.api_response && this.props.guestData) {
        const { first_name, last_name, lpn } = this.props.guestData;
        this.setState({
          first_name: first_name || "",
          last_name: last_name || "",
          plate_number: lpn || "",
          isFormValid: true,
        });
      }
    }
  };

  getEndDateOptions = (startDate, selectedIndex) => {
    const selectedDate = startDate;
    const endDate = moment(selectedDate).add(3, "days").format("YYYY-MM-DD");
    const filterData = this.state.date_slot.filter((item) => item.date <= endDate && item.date >= selectedDate);
    const tempDateOptions = filterData
      .map((item) =>
        item.timeslot.map((slot) => {
          let isDisable = false;
          if (slot.bookFull) {
            isDisable = true;
          }
          if (moment(slot.time_from, "HH:mm").isAfter(moment(slot.time_to, "HH:mm"))) {
            return {
              ...slot,
              startDate: item.date,
              isDisabledAfterFull: isDisable,
              slotDate: moment(item.date).add(1, "days").format("YYYY-MM-DD"),
            };
          }
          return {
            ...slot,
            startDate: item.date,
            slotDate: item.date,
            isDisabledAfterFull: isDisable,
            uniqueId: Math.random().toString(36).substr(2, 9),
          };
        }),
      )
      .flat();
    const tempoptionsss = JSON.parse(JSON.stringify(tempDateOptions)).splice(parseInt(selectedIndex), tempDateOptions.length);
    let isDisable = false;
    tempoptionsss.forEach((item) => {
      if (item.bookFull) {
        isDisable = true;
      }
      item.isDisabledAfterFull = isDisable;
    });
    const selectedOption = tempoptionsss.findIndex((option) => option.bookFull);
    if (this.state.startTimeList.length > 1 && this.state.startTimeList.length - 1 !== selectedIndex) {
      this.setState({
        selectTo: this.state.startTimeList[selectedIndex + 1].bookFull ? selectedOption - 1 : "",
      });
    }
    return tempoptionsss;
  };

  handleSelect = async (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        if (e.target.name === "selectDate" && e.target.value) {
          const selectedData = e.target.options ? JSON.parse(e.target?.options[e.target?.selectedIndex].dataset.item) : "";
          const item = selectedData.item;
          const timeslot = this.state.selectToData;
          timeslot.forEach((item, index) => {
            const isDisabledAfterFull = index < timeslot.findIndex((item) => item.bookFull === true);
            item.isDisabledAfterFull = isDisabledAfterFull;
          });
          this.setState({
            startTimeList: item,
            selectTo: "",
            selectTime: "",
          });
        }
        if (e.target.name === "selectTime" && e.target.value) {
          const selectedData = e.target.options ? JSON.parse(e.target?.options[e.target?.selectedIndex]?.dataset?.item) : "";
          const object = selectedData.item;
          const selectedDate = selectedData.keys;
          const endDate = moment(selectedDate).add(3, "days").format("YYYY-MM-DD");
          const filterData = this.state.date_slot.filter((item) => item.date <= endDate && item.date >= selectedDate);
          const tempDateOptions = filterData
            .map((item) =>
              item.timeslot.map((slot) => {
                let isDisable = false;
                if (slot.bookFull) {
                  isDisable = true;
                }
                if (moment(slot.time_from, "HH:mm").isAfter(moment(slot.time_to, "HH:mm"))) {
                  return {
                    ...slot,
                    startDate: item.date,
                    isDisabledAfterFull: isDisable,
                    slotDate: moment(item.date).add(1, "days").format("YYYY-MM-DD"),
                  };
                }
                return {
                  ...slot,
                  startDate: item.date,
                  slotDate: item.date,
                  isDisabledAfterFull: isDisable,
                  uniqueId: Math.random().toString(36).substr(2, 9),
                };
              }),
            )
            .flat();
          const tempoptionsss = JSON.parse(JSON.stringify(tempDateOptions)).splice(parseInt(e.target.value), tempDateOptions.length);
          let isDisable = false;
          tempoptionsss.forEach((item) => {
            if (item.bookFull) {
              isDisable = true;
            }
            item.isDisabledAfterFull = isDisable;
          });
          const selectedOption = tempoptionsss.findIndex((option) => option.bookFull);
          if (this.state.startTimeList.length > 1 && this.state.startTimeList.length - 1 !== selectedData.index) {
            this.setState({
              selectTo: this.state.startTimeList[selectedData.index + 1].bookFull ? selectedOption - 1 : "",
            });
          }
          console.log("tempoptionsss...........", tempoptionsss);
          this.setState({
            selectToData: tempoptionsss,
            selectStartTimeObject: object,
          });
        }
        this.validateFields();
      },
    );
  };

  validateFields = () => {
    const { first_name, last_name, contact_number, plate_number, selectDate, selectTime, selectTo } = this.state;
    const isDisabled = !!(first_name && last_name && contact_number && plate_number && selectDate && selectTime.toString() && selectTo);
    this.setState({
      isFormValid: isDisabled,
    });
  };

  showErrorMsg = (leftCredit) => {
    return leftCredit <= 0 && this.props.permits_allowed_type !== 2 && !this.props.visitor_credit_payment ? (
      <div className="help-block">{this.state.creditLimitMessage}</div>
    ) : null;
  };

  handleRedirectIssuePermitPackage = () => {
    const history = require("../../history").default;
    history.push({
      pathname: `/issue-permits-packages`,
    });
  };

  render() {
    const {
      isOpen,
      dayList,
      showBookingError,
      first_name,
      last_name,
      country_code,
      contact_number,
      plate_number,
      confirm_isopen,
      confirm_data,
      loader,
      usedCredits,
      allocatedCredits,
      leftCredit,
      guest_email,
      validEmail,
      wrongEmail,
      guest_email_enabled,
      // creditLimit,
      runningCredits,
      creditAllocated,
      user_type,
      validFirstName,
      validLastName,
      validContactNumber,
      api_success_message,
      show_alert,
      emptying_form,
      hideMsg,
      credit_alert,
      credit_msg,
      credit_used_30days,
      credit_30days_reports,
      single_credit_availability,
      api_error_message,
      show_alert_on_error,
      selected_suite_id,
      country_code_list,
      // phone_options,
      selectDate,
      selectTime,
      selectTo,
    } = this.state;
    const time = this.props.time_slot; // FROm API(reducer)
    return (
      <div>
        <Container fluid>
          <Row>
            <Col md="12">
              <Card>
                <Card.Header style={{ backgroundColor: this.props.main_body }}>
                  {show_alert && (
                    <Translation>{(t) => <CustomContentAlert delay={6000} message={t(api_success_message)} className="toast-success" />}</Translation>
                  )}
                  {!show_alert_on_error && (
                    <Translation>{(t) => <CustomContentAlert delay={6000} message={t(api_error_message)} className="toast-error" />}</Translation>
                  )}
                  {credit_alert && <Translation>{(t) => <CustomContentAlert delay={4000} message={t(credit_msg)} className="toast-error" />}</Translation>}
                  <div className="d-md-flex justify-content-between">
                    <Card.Title as="h4">
                      <Translation>{(t) => <>{t("GuestReservation")}</>}</Translation>
                      <span>
                        {this.props.UserProfileDetails?.suite_id && this.props.semiAuthorizedUserProfile
                          ? " (" + this.props.UserProfileDetails?.suite_id + ")"
                          : ""}
                      </span>
                    </Card.Title>
                    {user_type === "unit" && this.props.allPermissions.includes("issue_permit_packages") ? (
                      // <div className="d-sm-flex">
                      <button
                        className="btn btn-blue create-btn mt-md-0 mt-3"
                        type="button"
                        aria-controls="multiCollapseExample2"
                        onClick={this.handleRedirectIssuePermitPackage}
                      >
                        <Translation>{(t) => <>{t("My Licence Plates")}</>}</Translation>
                      </button>
                    ) : // </div>
                    null}
                  </div>
                </Card.Header>
                <Card.Body className="pos-rel-overflow-hide">
                  {this.props.permits_allowed_type !== 0 ? (
                    <CreditTable
                      isOpen={isOpen}
                      credit_type={this.props.credit_type === undefined ? this.state.credit_type : this.props.credit_type}
                      usedCredits={usedCredits}
                      allocatedCredits={allocatedCredits}
                      // creditLimit={creditLimit}                // Not used on cerdit table
                      dateRange={this.props.date_range}
                      runningCredits={runningCredits}
                      creditAllocated={creditAllocated}
                      leftCredit={leftCredit}
                      single_credit_availability={single_credit_availability}
                      credit_used_30days={credit_used_30days}
                      credit_30days_reports={credit_30days_reports}
                      handleButtonclick={() => this.setState({ isOpen: !this.state.isOpen })}
                      visitorDelete={(e) => this.visitorDelete(e)}
                      isOpenDateList={this.state.isOpenDateList}
                      timezone={this.props.timezone}
                      permits_allowed_type={this.props.permits_allowed_type}
                      template_name={this.props.template_name}
                      unit_language={this.state.unit_language}
                    />
                  ) : null}
                  <Form>
                    <Row>
                      {user_type !== "unit" ? (
                        <Col xl="4" lg="4" md="6" sm="12" xs="12">
                          <Form.Group>
                            <label>
                              <Translation>{(t) => <>{t("UnitYourGuestisVisiting")}</>}</Translation>
                              <span className="asterisk">*</span>
                            </label>
                            {this.state.residentList_.length > 0 ? (
                              <AsyncSelect
                                loadOptions={this.promiseOptions}
                                defaultOptions
                                cacheOptions
                                value={this.state.selectedValue}
                                onChange={(e) => this.handleInputSelect(e)}
                              />
                            ) : (
                              <></>
                            )}
                          </Form.Group>
                        </Col>
                      ) : null}
                    </Row>
                    <Row>
                      <Col xl="4" lg="6" md="6" sm="7" xs="12">
                        <label>
                          <Translation>{(t) => <>{t("GuestContactNumber")}</>}</Translation>
                          {!this.state.is_optional && <span className="asterisk">*</span>}
                        </label>
                        <Row>
                          <Col xxl="4" lg="5" sm="4" xs="5">
                            <Form.Group className="mb-3">
                              <Form.Control as="select" name="country_code" onChange={this.handleChange} value={country_code} required>
                                {country_code_list ? (
                                  <>
                                    {(country_code_list ?? []).map((item) => (
                                      <option key={item?.id} value={item.value}>
                                        {item.text}
                                      </option>
                                    ))}
                                  </>
                                ) : null}
                              </Form.Control>
                            </Form.Group>
                          </Col>
                          <Col xxl="8" lg="7" sm="8" xs="7">
                            <Form.Group className="mb-3">
                              <Translation>
                                {(t) => (
                                  <Form.Control
                                    placeholder={t("GuestContactNumber")}
                                    type="text"
                                    name="contact_number"
                                    value={contact_number}
                                    onChange={this.handleChange}
                                    disabled={loader}
                                    autoComplete="off"
                                    maxLength="10"
                                    required
                                  />
                                )}
                              </Translation>

                              {!emptying_form && !this.state.is_optional && validation_message(contact_number, "Phone Number", validContactNumber)}
                              {!this.state.is_optional && <span className="help-block">{this.state.phoneErrorMessage}</span>}
                            </Form.Group>
                          </Col>
                        </Row>
                      </Col>
                      <Col className="align-self-end mb-3">
                        <Button type="button" size="sm" className="btn-fill mr-2" variant="primary" onClick={this.findGuestData}>
                          <Translation>{(t) => <>{t("Find")}</>}</Translation>
                        </Button>
                        <small>
                          <Translation>{(t) => <>{t("InvitedThisPersonBeforeClickFind")}</>}</Translation>
                        </small>
                      </Col>
                    </Row>
                    <Row>
                      <Col xl="4" lg="4" md="6" sm="12">
                        <Form.Group className="mb-3">
                          <label>
                            <Translation>{(t) => <>{t("GuestFirstName")}</>}</Translation>
                            {!this.state.is_optional && <span className="asterisk">*</span>}
                          </label>
                          <Translation>
                            {(t) => (
                              <Form.Control
                                placeholder={t("GuestFirstName")}
                                type="text"
                                name="first_name"
                                value={first_name}
                                onChange={this.handleChange}
                                autoComplete="off"
                                disabled={loader}
                                required
                              />
                            )}
                          </Translation>
                          {!emptying_form && !this.state.is_optional && validation_message(first_name, "First Name", validFirstName)}
                        </Form.Group>
                      </Col>
                      <Col xl="4" lg="4" md="6" sm="12">
                        <Form.Group className="mb-3">
                          <label>
                            <Translation>{(t) => <>{t("GuestLastName")}</>}</Translation>
                            {!this.state.is_optional && <span className="asterisk">*</span>}
                          </label>
                          <Translation>
                            {(t) => (
                              <Form.Control
                                placeholder={t("GuestLastName")}
                                type="text"
                                name="last_name"
                                value={last_name}
                                onChange={this.handleChange}
                                disabled={loader}
                                autoComplete="off"
                                required
                              />
                            )}
                          </Translation>
                          {!emptying_form && !this.state.is_optional && validation_message(last_name, "Last Name", validLastName)}
                        </Form.Group>
                      </Col>
                      {guest_email_enabled > 0 ? (
                            <Col xl="4" lg="4" md="6" sm="12">
                              <Form.Group className="mb-3">
                                <label>
                                  Guest Email
                                  <span className="asterisk">*</span>
                                </label>
                                <Form.Control
                                  placeholder="example@gmail.com"
                                  type="text"
                                  name="guest_email"
                                  value={guest_email}
                                  autoComplete="off"
                                  onChange={this.handleChange}
                                  required
                                />
                                {email_validation_message(guest_email, validEmail, wrongEmail)}
                              </Form.Group>
                            </Col>
                      ) : null}
                      <Col xl="4" lg="4" md="6" sm="12">
                        <Form.Group className="mb-3">
                          <label>
                            <Translation>{(t) => <>{t("GuestLicensePlateNumber")}</>}</Translation>
                            <span className="asterisk">*</span>
                          </label>
                          <Translation>
                            {(t) => (
                              <Form.Control
                                placeholder={t("PlateNumber")}
                                type="text"
                                name="plate_number"
                                value={plate_number}
                                onChange={this.handleChange}
                                disabled={loader}
                                autoComplete="off"
                                maxLength="11"
                                required
                              />
                            )}
                          </Translation>
                          {!emptying_form && validation_message(plate_number, "License Plate Number", this.state.validPlateNumber)}
                        </Form.Group>
                      </Col>
                    </Row>
                    {(this.state.user_type === "admin" || this.state.user_type === "superadmin") && this.props.permits_allowed_type === 0 ? (
                      <div className="help-block">
                        <Translation>{(t) => <>{t("This_unit_is_not_allowed_to_add_visitors")}</>}</Translation>{" "}
                      </div>
                    ) : (
                      <>
                        {console.log("Grid::::::", loader, this.state.isOpenDateList)}
                        {!loader && this.state.isOpenDateList ? (
                          <div>
                            {dayList &&
                            Object.keys(dayList).length &&
                            (leftCredit > 0 || (leftCredit <= 0 && this.props.visitor_credit_payment) || this.props.permits_allowed_type === 2) ? (
                              <div>
                                <div className={"d-md-block d-none"}>
                                  <Table className="table-hover table-striped table-reservation mt-3 add-visitor-table" responsive bordered size="sm">
                                    <thead className="timing-header">
                                      <tr>
                                        <th> </th>
                                        {time?.map((item, index) => (
                                          <th
                                            style={{
                                              color: "#000",
                                              fontSize: "11px",
                                            }}
                                            key={index}
                                          >
                                            <Translation>{(t) => <>{t(item?.alias)}</>}</Translation>
                                          </th>
                                        ))}
                                      </tr>
                                      <tr>
                                        <th
                                          style={{
                                            color: "#000",
                                            minWidth: "110px",
                                          }}
                                        >
                                          Date
                                        </th>
                                        {time?.map((item, index) => (
                                          <th key={index}>
                                            <span
                                              style={{
                                                color: "#bf8f00",
                                                fontSize: "12px",
                                              }}
                                            >
                                              {item.time_to
                                                ? gettimeSlotDateInFrenchLanguage(moment(item.time_from, ["HH:mm"]).format("HH:mm A"), this.state.unit_language)
                                                : "-"}
                                            </span>
                                            -{" "}
                                            <span
                                              style={{
                                                color: "#bf8f00",
                                                fontSize: "12px",
                                              }}
                                            >
                                              {item.time_to
                                                ? gettimeSlotDateInFrenchLanguage(moment(item.time_to, ["HH:mm"]).format("HH:mm A"), this.state.unit_language)
                                                : "-"}
                                            </span>{" "}
                                          </th>
                                        ))}
                                      </tr>
                                    </thead>
                                    <tbody id="checkedBox">
                                      {Object.entries(dayList).map(([keys, value], index) => {
                                        return (
                                          <tr key={keys}>
                                            <td>
                                              <span className="font-12">
                                                {gettemplateDateInFrenchLanguage(moment(keys).format("ddd MMM D, 'YY"), this.state.unit_language)}
                                              </span>
                                            </td>

                                            {value.map((item, columnIndex) => {
                                              return (
                                                <td align="center" key={item.id + item.template_id}>
                                                  <div className="custom-control custom-checkbox p-0">
                                                    {item.isBooked ? (
                                                      <span
                                                        style={{
                                                          color: "red",
                                                        }}
                                                      >
                                                        <Translation>{(t) => <>{t("Full")}</>}</Translation>{" "}
                                                      </span>
                                                    ) : (
                                                      <>
                                                        <input
                                                          type="checkbox"
                                                          className="custom-control-input"
                                                          id={"tableDefaultCheck" + columnIndex + index}
                                                          onChange={() => this.handleCheckBox(keys, columnIndex, item)}
                                                          checked={item.isChecked}
                                                          disabled={item?.isDisabled}
                                                          title={item.availableSpots + " spots left"}
                                                        />
                                                        <label className="custom-control-label" htmlFor={"tableDefaultCheck" + columnIndex + index}>
                                                          {" "}
                                                        </label>
                                                      </>
                                                    )}
                                                  </div>
                                                </td>
                                              );
                                            })}
                                          </tr>
                                        );
                                      })}
                                    </tbody>
                                  </Table>
                                </div>
                                {/* {this.state.mobile_view_option && ( */}
                                <Row>
                                  <Col sm="12" className={"d-md-none"}>
                                    <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                                      <Form.Label column xs={3}>
                                        <Translation>{(t) => <>{t("Start Date")}</>}</Translation>
                                        <span className="asterisk">*</span>
                                      </Form.Label>
                                      <Col xs={9}>
                                        <Form.Control as="select" name="selectDate" value={selectDate} onChange={this.handleSelect} placeholder="Select Date">
                                          <Translation>{(t) => <option value="">{t("SelectDate")}</option>}</Translation>
                                          {this.state.date_slot.map((value, index) => {
                                            const optionData = {
                                              item: value.timeslot,
                                              keys: index,
                                            };
                                            return (
                                              <option key={value.order_id + value.id} value={value.date} data-item={JSON.stringify(optionData)}>
                                                {gettemplateDateInFrenchLanguage(moment(value.date).format("ddd MMM Do YYYY"), this.state.unit_language)}
                                              </option>
                                            );
                                          })}
                                        </Form.Control>
                                      </Col>
                                    </Form.Group>
                                  </Col>
                                  <Col sm="12" className="d-md-none">
                                    <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                                      <Form.Label column xs={3}>
                                        <Translation>{(t) => <>{t("Start Time")}</>}</Translation>
                                        <span className="asterisk">*</span>
                                      </Form.Label>
                                      <Col xs={9}>
                                        <Form.Control
                                          as="select"
                                          name="selectTime"
                                          value={selectTime}
                                          onChange={(e) => this.handleSelect(e)}
                                          placeholder="Select Time"
                                        >
                                          <Translation>{(t) => <option value="">{t("SelectTime")}</option>}</Translation>

                                          {this.state.startTimeList.map((item, columnIndex) => {
                                            const optionValue = columnIndex;
                                            const optionData = {
                                              item: item,
                                              keys: selectDate,
                                              index: columnIndex,
                                            };
                                            return (
                                              <option
                                                key={item.order_id + "time"}
                                                value={optionValue}
                                                data-item={JSON.stringify(optionData)}
                                                disabled={item.bookFull}
                                                className={item.bookFull && "text-danger"}
                                              >
                                                {item.time_from
                                                  ? gettimeSlotDateInFrenchLanguage(
                                                      moment(item.time_from, ["HH:mm"]).format("h:mm A"),
                                                      this.state.unit_language,
                                                    )
                                                  : "-"}
                                                {item.bookFull && `Full`}
                                              </option>
                                            );
                                          })}
                                        </Form.Control>
                                      </Col>
                                    </Form.Group>
                                  </Col>
                                  {selectTime !== "" && (
                                    <Col sm="12" className="d-md-none">
                                      <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                                        <Form.Label column xs={3}>
                                          <Translation>{(t) => <>{t("To")}</>}</Translation>
                                          <span className="asterisk">*</span>
                                        </Form.Label>
                                        <Col xs={9}>
                                          <Form.Control
                                            as="select"
                                            name="selectTo"
                                            value={selectTo}
                                            onChange={(e) => this.handleSelect(e)}
                                            placeholder="Select Time"
                                          >
                                            <Translation>{(t) => <option value="">{t("SelectTo")}</option>}</Translation>
                                            {this.state.selectToData.map((values, index) => {
                                              const optionValue = index;
                                              return (
                                                <option
                                                  key={values.uniqueId}
                                                  value={optionValue}
                                                  disabled={values.bookFull || values.isDisabledAfterFull}
                                                  className={values.bookFull && "text-danger"}
                                                >
                                                  {`${gettemplateDateInFrenchLanguage(
                                                    moment(values.slotDate).format("ddd MMM Do YYYY"),
                                                    this.state.unit_language,
                                                  )} ${
                                                    values.time_to
                                                      ? gettimeSlotDateInFrenchLanguage(
                                                          moment(values.time_to, ["HH:mm"]).format("h:mm A"),
                                                          this.state.unit_language,
                                                        )
                                                      : "-"
                                                  }`}
                                                  {values.bookFull && `Full`}
                                                </option>
                                              );
                                            })}
                                          </Form.Control>
                                        </Col>
                                      </Form.Group>
                                    </Col>
                                  )}
                                </Row>
                                {/* )} */}
                              </div>
                            ) : null}

                            {(this.state.user_type === "admin" || this.state.user_type === "superadmin") && !selected_suite_id.length ? (
                              <div className="help-block">{``}</div>
                            ) : (
                              this.showErrorMsg(leftCredit)
                            )}
                          </div>
                        ) : null}
                      </>
                    )}
                  </Form>
                  <Row>
                    <Col md="12">
                      {showBookingError && !hideMsg ? (
                        <div className="mb-4">
                          <span className="help-block">
                            <Translation>{(t) => <>{t("Please_select_atleast_one_spot_for_reservation")}</>}</Translation>
                          </span>
                        </div>
                      ) : null}
                      {!loader ? (
                        <div className="visitorPermit-controls mt-3 d-flex justify-content-end">
                          {/* {this.state.mobile_view_option && ( */}
                          <Button
                            type="button"
                            className={"btn-fill d-md-none"}
                            variant="primary"
                            disabled={
                              !this.state.isFormValid // &&
                              // !this.state.isMobileFormValid
                            }
                            onClick={this.handleCreateReservationForMobile}
                          >
                            <Translation>{(t) => <>{t("Reserve")}</>}</Translation>
                          </Button>
                          {/* )} */}
                          <Button
                            type="button"
                            className={"btn-fill d-md-block d-none"}
                            variant="primary"
                            // disabled={loader}
                            disabled={!this.state.isFormValid}
                            onClick={this.handleCreateReservation}
                          >
                            <Translation>{(t) => <>{t("Reserve")}</>}</Translation>
                          </Button>
                        </div>
                      ) : (
                        <Loader />
                      )}
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
        {confirm_isopen ? (
          <ConfirmReservation
            showBrowserModal={confirm_isopen}
            closeModal={this.handleToogle}
            confirm_data={confirm_data}
            onSubmit={this.handleSubmit}
            isButtonDisable={!this.state.isButtonDisable}
            unit_language={this.state.unit_language}
          />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = ({ CurrentPermitDetails, APIResponseDetails, UserProfileDetails, SuperAdminDetails, Logindetails, PermitOptionsDetails }) => {
  const {
    currentCredits,
    usedCredits,
    allocatedCredits,
    usedAllocatedCredits,
    data_parked,
    multiple_permits,
    resident_permits,
    time_slot,
    date_slot,
    credit_30days_reports,
    credit_used_30days,
    single_credit_availability,
    residentList,
    date_range,
    permits_allowed_type,
    template_name,
    guestData,
    updateUnitLanguage: selectedLang,
  } = CurrentPermitDetails;
  const { api_response, api_error_message, api_success_message } = APIResponseDetails;
  const { time_slot_templates, templateCreditGroup, permissions: allPermissions } = SuperAdminDetails;
  const { cardDetails } = PermitOptionsDetails;
  const {
    credits,
    suite_id,
    property_id,
    user_type,
    available_spot,
    appConfig,
    globaleCredit,
    credit_type,
    day_availablity,
    evening_availability,
    night_availability,
    day,
    evening,
    night,
    company_id,
    property,
    timezone,
    mobile_view_option,
    visitor_credit_payment,
    is_optional,
    language,
  } = UserProfileDetails;
  const { backgroundColor, semiAuthorizedUserProfile } = Logindetails;
  const { MainBackground: main_body } = backgroundColor;
  console.log("Visitor credit payment:: flag:::111", visitor_credit_payment);
  return {
    mobile_view_option,
    is_optional,
    template_name,
    permits_allowed_type,
    semiAuthorizedUserProfile,
    timezone,
    multiple_permits,
    time_slot,
    templateCreditGroup,
    globaleCredit,
    credit_30days_reports,
    credit_used_30days,
    date_slot,
    currentCredits,
    usedCredits,
    date_range,
    allocatedCredits,
    usedAllocatedCredits,
    single_credit_availability,
    time_slot_templates,
    data_parked,
    api_response,
    api_error_message,
    credits,
    suite_id,
    property_id,
    UserProfileDetails,
    user_type,
    company_id,
    available_spot,
    resident_permits,
    appConfig,
    api_success_message,
    credit_type,
    day_availablity,
    evening_availability,
    night_availability,
    day,
    evening,
    night,
    property,
    residentList,
    main_body,
    guestData,
    language,
    selectedLang,
    visitor_credit_payment,
    cardDetails,
    allPermissions,
  };
};

const mapStateToDispatch = {
  getCurrentPermits: CurrentPermitActions.getCurrentPermits,
  getCreditsIndividual: CurrentPermitActions.getCreditsIndividual,
  fetchResidentList: CurrentPermitActions.fetchResidentList,
  selectedUnitLanguage: CurrentPermitActions.selectedUnitLanguage,
  getCreditTemplateGroups: SuperAdminAction.getCreditTemplateGroups,
  getCreditsIndividualDetails: CurrentPermitActions.getCreditsIndividualDetails,
  getPastCreditsIndividual: CurrentPermitActions.getPastCreditsIndividual,
  AddPermits: CurrentPermitActions.AddPermits,
  InitializePermitAllowedType: CurrentPermitActions.InitializePermitAllowedType,
  getTimeSlot: CurrentPermitActions.getTimeSlot,
  getSettings: SuperAdminAction.getSettings,
  APIResponseSuccess: APIResponseActions.APIResponseSuccess,
  getCredits: CurrentPermitActions.getCredits,
  getPastCredits: CurrentPermitActions.getPastCredits,
  setResidentCredits: CurrentPermitActions.setResidentCredits,
  getResidentPermits: CurrentPermitActions.getResidentPermits,
  getResidents: CurrentPermitActions.setResidentCredits,
  setDomainHeader: SuperAdminAction.setDomainHeader,
  visitorPermitDelete: CurrentPermitActions.visitorPermitDelete,
  GetGuestData: CurrentPermitActions.GetGuestData,
  getCardDetails: PermitOptionsActions.getCardDetails,
};

export default connect(mapStateToProps, mapStateToDispatch)(VisitorPermit);
