import t from "../../Actions/ReportsAction/types";

const intitialState = {
  ticketDispenserList: [],
  ticketValidationList: [],
  ticketBatchValidationList: [],
  ticketValidationSummary: [],
  getPaymentReceiptData: "",
  cashoutList: [],
  cashoutExportList: "",
  batteryList: [],
  batteryExportList: "",
  loader: false,
  agentIdList: [],
  paidAnalysisList: [],
  paidAnalysisExportList: "",
  merchant_agentId_List: [],
  accessLogList: [],
  accessExportHistoryList: "",
  passagepassRateList: [],
  passagepassRateTypeList: [],
  parkingLocationList: [],
  ratebufferData: "",
};

const Reducer = (state = intitialState, action) => {
  switch (action.type) {
    case t.FETCH_TICKET_DISPENSER_LIST: {
      return { ...state, ticketDispenserList: action.payload };
    }
    case t.FETCH_VALIDATION_LIST: {
      return { ...state, ticketValidationList: action.payload };
    }
    case t.FETCH_VALIDATION_AGENT_LIST: {
      return { ...state, merchant_agentId_List: action.payload };
    }
    case t.FETCH_VALIDATION_SUMMARY_LIST: {
      return { ...state, ticketValidationSummary: action.payload };
    }
    case t.FETCH_BATCH_VALIDATION_LIST: {
      return { ...state, ticketBatchValidationList: action.payload };
    }
    case t.FETCH_PAYMENT_DOWNLOAD_DATA: {
      return { ...state, getPaymentReceiptData: action.payload };
    }
    case t.FETCH_ADMIN_INVOICE_LIST: {
      return { ...state, adminInvoiceList: action.payload };
    }
    case t.FETCH_USAGE_INVOICE_LIST: {
      return { ...state, usageInvoiceList: action.payload };
    }
    case t.FETCH_UNIT_INVOICE_LIST: {
      return { ...state, unitInvoiceList: action.payload };
    }
    case t.FETCH_INVOICE_EXPORT_LIST: {
      return { ...state, invoiceExportList: action.payload };
    }
    case t.FETCH_ADMIN_INVOICE_EXPORT_LIST: {
      return { ...state, adminInvoiceExportList: action.payload };
    }
    case t.FETCH_USAGE_INVOICE_EXPORT_LIST: {
      return { ...state, usageInvoiceExportList: action.payload };
    }
    case t.FETCH_ADMIN_INVOICE_EXPORT_PDF_LIST: {
      return { ...state, invoiceExportPdfList: action.payload };
    }
    case t.FETCH_OVERUSAGE_INVOICE_EXPORT_PDF_LIST: {
      return { ...state, overusageinvoiceExportPdfList: action.payload };
    }
    case t.FETCH_CASHOUT_EXPORT_LIST: {
      return { ...state, cashoutExportList: action.payload };
    }
    case t.FETCH_CASHOUT_LIST: {
      return { ...state, cashoutList: action.payload };
    }
    case t.FETCH_ACCESSLOG_EXPORT_LIST: {
      return { ...state, accessLogList: action.payload };
    }
    case t.FETCH_ACCESSLOG_LIST: {
      return { ...state, accessLogList: action.payload };
    }
    case t.SHOW_HIDE_LOADER: {
      return { ...state, loader: action.payload };
    }
    case t.FETCH_CASHOUT_UPDATE: {
      const prevcashoutList = state.cashoutList;
      const updatedcashoutList = (prevcashoutList?.rows ?? []).map((item) => {
        if (item.cashout_id === action.payload?.id) {
          item.cash_float = +action.payload?.cash_float && parseFloat(action.payload?.cash_float).toFixed(2);
          item.last_audited_cashout = +action.payload?.audited_cashout && parseFloat(action.payload?.audited_cashout).toFixed(2);
          if (action.payload.type === "audited_amount") {
            item.audited_username = action.payload?.audited_username;
          }
        }
        return item;
      });
      return {
        ...state,
        cashoutList: {
          ...state,
          rows: JSON.parse(JSON.stringify(updatedcashoutList)),
        },
      };
    }
    case t.FETCH_CASHOUT_INSERT: {
      const prevcashoutList = state.cashoutList;
      const updatedcashoutList = (prevcashoutList?.rows ?? []).map((item) => {
        if (item.agent_id === action.payload?.agent_id) {
          item.cash_float = +action.payload?.cash_float && parseFloat(action.payload?.cash_float).toFixed(2);
          item.last_audited_cashout = +action.payload?.last_audited_cashout && parseFloat(action.payload?.last_audited_cashout).toFixed(2);
          item.cashout_id = action.payload?.cashout_id;
          item.last_collected_time = action.payload?.last_collected_time;
          if (action.payload.type === "audited_amount") {
            item.audited_username = action.payload?.audited_username;
          }
        }
        return item;
      });
      return {
        ...state,
        cashoutList: {
          ...state,
          rows: JSON.parse(JSON.stringify(updatedcashoutList)),
        },
      };
    }
    case t.FETCH_BATTERY_EXPORT_LIST: {
      return { ...state, batteryExportList: action.payload };
    }
    case t.FETCH_BATTERY_LIST: {
      return { ...state, batteryList: action.payload };
    }
    case t.FETCH_PAID_ANALYSIS_EXPORT_LIST: {
      return { ...state, paidAnalysisExportList: action.payload };
    }
    case t.FETCH_PAID_ANALYSIS_LIST: {
      return { ...state, paidAnalysisList: action.payload };
    }
    case t.FETCH_AGENT_LIST: {
      return { ...state, agentIdList: action.payload };
    }
    case t.FETCH_RATE_LIST: {
      return { ...state, passagepassRateList: action.payload };
    }
    case t.FETCH_LOCATION_LIST: {
      return { ...state, parkingLocationList: action.payload };
    }
    case t.FETCH_RATE_TYPE_LIST: {
      return { ...state, passagepassRateTypeList: action.payload };
    }
    case t.EXPORT_RATE_LIST: {
      return { ...state, ratebufferData: action.payload };
    }
    case t.DELETE_RATE_LIST: {
      const List = state.passagepassRateList;
      const updatedPassagepassRateList = List?.data.filter((item) => item.id !== action.payload);
      console.log("updatedPassagepassRateList::::", updatedPassagepassRateList, action.payload);
      return {
        ...state,
        passagepassRateList: {
          ...state.passagepassRateList,
          data: updatedPassagepassRateList,
        },
      };
    }
    default:
      return state;
  }
};

export default Reducer;
