const Types = {
  FETCH_TICKET_DISPENSER_LIST: "FETCH_TICKET_DISPENSER_LIST",
  FETCH_VALIDATION_LIST: "FETCH_VALIDATION_LIST",
  FETCH_PAYMENT_DOWNLOAD_DATA: "FETCH_PAYMENT_DOWNLOAD_DATA",
  FETCH_ADMIN_INVOICE_LIST: "FETCH_ADMIN_INVOICE_LIST",
  FETCH_UNIT_INVOICE_LIST: "FETCH_UNIT_INVOICE_LIST",
  FETCH_INVOICE_EXPORT_LIST: "FETCH_INVOICE_EXPORT_LIST",
  FETCH_ADMIN_INVOICE_EXPORT_LIST: "FETCH_ADMIN_INVOICE_EXPORT_LIST",
  FETCH_ADMIN_INVOICE_EXPORT_PDF_LIST: "FETCH_ADMIN_INVOICE_EXPORT_PDF_LIST",
  FETCH_VALIDATION_SUMMARY_LIST: "FETCH_VALIDATION_SUMMARY_LIST",
  FETCH_BATCH_VALIDATION_LIST: "FETCH_BATCH_VALIDATION_LIST",
  FETCH_CASHOUT_EXPORT_LIST: "FETCH_CASHOUT_EXPORT_LIST",
  FETCH_CASHOUT_LIST: "FETCH_CASHOUT_LIST",
  FETCH_CASHOUT_INSERT: "FETCH_CASHOUT_INSERT",
  FETCH_CASHOUT_UPDATE: "FETCH_CASHOUT_UPDATE",
  SHOW_HIDE_LOADER: "SHOW_HIDE_LOADER",
  FETCH_BATTERY_LIST: "FETCH_BATTERY_LIST",
  FETCH_BATTERY_EXPORT_LIST: "FETCH_BATTERY_EXPORT_LIST",
  FETCH_PAID_ANALYSIS_LIST: "FETCH_PAID_ANALYSIS_LIST",
  FETCH_PAID_ANALYSIS_EXPORT_LIST: "FETCH_PAID_ANALYSIS_EXPORT_LIST",
  FETCH_VALIDATION_AGENT_LIST: "FETCH_VALIDATION_AGENT_LIST",
  FETCH_OVERUSAGE_INVOICE_EXPORT_PDF_LIST: "FETCH_OVERUSAGE_INVOICE_EXPORT_PDF_LIST",
  FETCH_USAGE_INVOICE_EXPORT_LIST: "FETCH_USAGE_INVOICE_EXPORT_LIST",
  FETCH_USAGE_INVOICE_LIST: "FETCH_USAGE_INVOICE_LIST",
  FETCH_ACCESSLOG_EXPORT_LIST: "FETCH_ACCESSLOG_EXPORT_LIST",
  FETCH_ACCESSLOG_LIST: "FETCH_ACCESSLOG_LIST",
  EXPORT_RATE_LIST: "EXPORT_RATE_LIST",
  CREATE_RATE_LIST: "CREATE_RATE_LIST",
  FETCH_RATE_LIST: "FETCH_RATE_LIST",
  UPDATE_RATE_LIST: "UPDATE_RATE_LIST",
  DELETE_RATE_LIST: "DELETE_RATE_LIST",
  FETCH_RATE_TYPE_LIST: "FETCH_RATE_TYPE_LIST",
  FETCH_LOCATION_LIST: "FETCH_LOCATION_LIST",
};

export default Types;
